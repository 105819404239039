import axios from '@utils/axiosInstance'
import sortOptionsByKey from '@utils/sortOptionsByKey'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    laborCondition: null,
    positions: [],
    timebaseChangeProcesses: [],
    shiftReductionProcesses: [],
    complementaryPactProcesses: [],
    timebaseChangeDocument: null,
    complementaryPactDocument: null,
    pendingSapHourChangeProcesses: [],
    teamOrPositionChangeProcesses: [],
    signaturePendingContractLaborCondition: null,
    shiftReductionProcess: null,
    complementaryPactProcess: null,
    timebaseChangeProcess: null,
  }
}
export const state = getDefaultState()

export const getters = {}

export const mutations = {
  UPDATE_LABOR_CONDITION(state, laborCondition) {
    state.laborCondition = laborCondition
  },
  UPDATE_POSITIONS(state, positions) {
    sortOptionsByKey(positions, 'name')
    state.positions = sortPositions(positions)
  },
  UPDATE_TIMEBASE_CHANGE_PROCESSES(state, timebaseChangeProcesses) {
    state.timebaseChangeProcesses = timebaseChangeProcesses.data
  },
  UPDATE_SHIFT_REDUCTION_PROCESSES(state, shiftReductionProcesses) {
    state.shiftReductionProcesses = shiftReductionProcesses.data
  },
  UPDATE_COMPLEMENTARY_PACT_PROCESSES(state, complementaryPactProcesses) {
    state.complementaryPactProcesses = complementaryPactProcesses.data
  },
  UPDATE_TIMEBASE_CHANGE_DOCUMENT(state, timebaseChangeDocument) {
    state.timebaseChangeDocument = timebaseChangeDocument
  },
  UPDATE_COMPLEMENTARY_PACT_DOCUMENT(state, complementaryPactDocument) {
    state.complementaryPactDocument = complementaryPactDocument
  },
  UPDATE_PENDING_SAP_HOUR_CHANGE_PROCESSES(
    state,
    pendingSapHourChangeProcesses,
  ) {
    state.pendingSapHourChangeProcesses = pendingSapHourChangeProcesses
  },
  UPDATE_TEAM_OR_POSITION_CHANGES(state, teamOrPositionChangeProcesses) {
    state.teamOrPositionChangeProcesses = teamOrPositionChangeProcesses
  },
  RESET_LABOR_CONDITION_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  UPDATE_PENDING_CONTRACTS_PROCESSES_LABOR_CONDITION(
    state,
    signaturePendingContractLaborCondition,
  ) {
    state.signaturePendingContractLaborCondition =
      signaturePendingContractLaborCondition
  },
  UPDATE_SHIFT_REDUCTION_PROCESS(state, shiftReductionProcess) {
    state.shiftReductionProcess = shiftReductionProcess
  },
  UPDATE_TIMEBASE_CHANGE_PROCESS(state, timebaseChangeProcess) {
    state.timebaseChangeProcess = timebaseChangeProcess
  },
  UPDATE_COMPLEMENTARY_PACT_PROCESS(state, complementaryPactProcess) {
    state.complementaryPactProcess = complementaryPactProcess
  },
}

export const actions = {
  // POSITIONS
  // GET
  async fetchPositions(store, filters = {}) {
    store.commit('UPDATE_POSITIONS', [])
    try {
      const filtersArr = []
      Object.keys(filters).forEach((key) => {
        if (filters[key] !== null || filters[key] !== undefined) {
          filtersArr.push(key + '=' + filters[key])
        }
      })
      const queryParams =
        filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
      const res = await axios.get(`${BASE}/es/api/v2/positions${queryParams}`)
      store.commit('UPDATE_POSITIONS', res.data)
    } catch (e) {
      throw Error('API Error occurred.')
    }
  },

  // TIMEBASE CHANGE PROCESSES
  // GET
  async fetchTimebaseChangeProcessesAction(store, filters = {}) {
    store.commit('UPDATE_TIMEBASE_CHANGE_PROCESSES', [])
    const filtersArr = joinFilters(filters)
    const queryParams = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/es/api/v2/timebaseChangeProcess${queryParams}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_TIMEBASE_CHANGE_PROCESSES', res.data)
    } catch (e) {
      throw Error(`API Error occurred in ${url}`)
    }
  },
  // SHIFT REDUCTION PROCESSES
  // GET
  async fetchShiftReductionProcessesAction(store, filters = {}) {
    store.commit('UPDATE_SHIFT_REDUCTION_PROCESSES', [])
    const filtersArr = joinFilters(filters)
    const queryParams = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/es/api/v2/shiftReductionProcess${queryParams}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_SHIFT_REDUCTION_PROCESSES', res.data)
    } catch (e) {
      throw Error(`API Error occurred in ${url}`)
    }
  },
  // TIMEBASE CHANGE PROCESSES SITE
  // GET
  async fetchSiteTimebaseChangeProcessesAction(store, personnelDivision) {
    store.commit('UPDATE_TIMEBASE_CHANGE_PROCESSES', [])
    const urlTimebase = `${BASE}/es/api/v2/sites/${personnelDivision}/timebaseChangeProcess`
    try {
      const resTimebase = await axios.get(urlTimebase)
      store.commit('UPDATE_TIMEBASE_CHANGE_PROCESSES', resTimebase.data)
    } catch (e) {
      throw Error(`API Error occurred in ${urlTimebase}`)
    }
  },
  // TIMEBASE CHANGE PROCESSES SITE
  // GET
  async fetchSiteShiftReductionProcessesAction(store, personnelDivision) {
    store.commit('UPDATE_SHIFT_REDUCTION_PROCESSES', [])
    const urlReduction = `${BASE}/es/api/v2/sites/${personnelDivision}/shiftReductionProcess`
    try {
      const resReduction = await axios.get(urlReduction)
      store.commit('UPDATE_SHIFT_REDUCTION_PROCESSES', resReduction.data)
    } catch (e) {
      throw Error(`API Error occurred in  ${urlReduction} `)
    }
  },
  // COMPLEMENTARY PACT PROCESSES
  // GET
  async fetchComplementaryPactProcesses(store, filters = {}) {
    store.commit('UPDATE_COMPLEMENTARY_PACT_PROCESSES', [])
    const filtersArr = joinFilters(filters)
    const queryParams = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/es/api/v2/complementaryPactProcess${queryParams}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_COMPLEMENTARY_PACT_PROCESSES', res.data)
    } catch (e) {
      throw Error(`API Error occurred in ${url}`)
    }
  },
  // COMPLEMENTARY PACT PROCESSES SITE
  // GET
  async fetchSiteComplementaryPactProcesses(store, personnelDivision) {
    store.commit('UPDATE_COMPLEMENTARY_PACT_PROCESSES', [])
    try {
      const resReduction = await axios.get(
        `${BASE}/es/api/v2/sites/${personnelDivision}/complementaryPactProcess`,
      )
      store.commit('UPDATE_COMPLEMENTARY_PACT_PROCESSES', resReduction.data)
    } catch (e) {
      throw Error(
        `API Error occurred in  ${BASE}/es/api/v2/sites/${personnelDivision}/complementaryPactProcess`,
      )
    }
  },
  // TIMEBASE CHANGE DOCUMENT
  // GET
  async fetchTimebaseChangeDocument(store, processUuid) {
    store.commit('UPDATE_TIMEBASE_CHANGE_DOCUMENT', null)
    try {
      const response = await axios.get(
        `${BASE}/es/api/v2/timebase.getDocumentPreviewInBase64?uuid=${processUuid}`,
      )
      store.commit('UPDATE_TIMEBASE_CHANGE_DOCUMENT', response.data)
    } catch (e) {
      throw Error(
        `API Error occurred in  ${BASE}/es/api/v2/timebase.getDocumentPreviewInBase64?uuid=${processUuid}`,
      )
    }
  },
  // TIMEBASE CHANGE DOCUMENT
  // GET
  async fetchComplementaryPactDocument(store, processUuid) {
    store.commit('UPDATE_COMPLEMENTARY_PACT_DOCUMENT', null)
    try {
      const response = await axios.get(
        `${BASE}/es/api/v2/compPact.getDocumentPreviewInBase64?uuid=${processUuid}`,
      )
      store.commit('UPDATE_COMPLEMENTARY_PACT_DOCUMENT', response.data)
    } catch (e) {
      throw Error(
        `API Error occurred in  ${BASE}/es/api/v2/compPact.getDocumentPreviewInBase64?uuid=${processUuid}`,
      )
    }
  },
  // SAP PENDING HOUR CHANGE PROCESSES
  // GET

  async fetchPendingSapHourChangeProcesses(store, filters = {}) {
    store.commit('UPDATE_PENDING_SAP_HOUR_CHANGE_PROCESSES', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    try {
      const resTimebase = await axios.get(
        `${BASE}/es/api/v2/timebase.manageInSap${queryString}`,
      )
      const resReduction = await axios.get(
        `${BASE}/es/api/v2/reduction.manageInSap${queryString}`,
      )
      store.commit('UPDATE_PENDING_SAP_HOUR_CHANGE_PROCESSES', [
        ...resReduction.data,
        ...resTimebase.data,
      ])
      return [...resReduction.data, ...resTimebase.data]
    } catch (error) {
      throw new Error(
        'API Error occurred in fetchPendingSapHourChangeProcesses' + error,
      )
    }
  },

  // TEAM OR POSITION CHANGE PROCESSES
  // GET
  async fetchTeamOrPositionChangeProcessesAction(store, filters) {
    store.commit('UPDATE_TEAM_OR_POSITION_CHANGES', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    try {
      let resPosition = null
      let resTeam = null

      const changeTypes = ['TEAM_CHANGES', 'POSITION_CHANGES']

      const checker = (arr, target) =>
        target.every((changeType) => arr.includes(changeType))

      if (
        filters.processType === null ||
        checker(filters.processType, changeTypes)
      ) {
        resTeam = await axios.get(
          `${BASE}/es/api/v2/team.changes${queryString}`,
        )
        resPosition = await axios.get(
          `${BASE}/es/api/v2/position.changes${queryString}`,
        )
      } else if (filters.processType.includes('TEAM_CHANGES')) {
        resTeam = await axios.get(
          `${BASE}/es/api/v2/team.changes${queryString}`,
        )
      } else {
        resPosition = await axios.get(
          `${BASE}/es/api/v2/position.changes${queryString}`,
        )
      }

      resTeam = resTeam ? resTeam.data : []
      resPosition = resPosition ? resPosition.data : []
      store.commit('UPDATE_TEAM_OR_POSITION_CHANGES', [
        ...resTeam,
        ...resPosition,
      ])
    } catch (error) {
      throw Error(
        `API Error occurred in fetchTeamOrPositionChangeProcessesAction: ${error}`,
      )
    }
  },
  // FETCH SHIFT REDUCTION PROCESS
  // GET
  async fetchShiftReductionProcessAction(store, payload) {
    try {
      store.commit('UPDATE_SHIFT_REDUCTION_PROCESS', null)
      const response = await axios.get(
        `${BASE}/es/api/v2/laborConditionManager/reduction/` +
          payload.processUuid,
      )
      store.commit('UPDATE_SHIFT_REDUCTION_PROCESS', response.data)
      return response
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/laborConditionManager/reduction/` +
          payload.processUuid,
      )
    }
  },
  // FETCH COMPLEMENTARY PACT PROCESS
  // GET
  async fetchComplementaryPactProcessAction(store, payload) {
    try {
      store.commit('UPDATE_COMPLEMENTARY_PACT_PROCESS', null)
      const response = await axios.get(
        `${BASE}/es/api/v2/laborConditionManager/compPact/` +
          payload.processUuid,
      )
      store.commit('UPDATE_COMPLEMENTARY_PACT_PROCESS', response.data)
      return response
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/laborConditionManager/compPact/` +
          payload.processUuid,
      )
    }
  },
  // FETCH TIMEBASE CHANGE PROCESS
  // GET
  async fetchTimebaseChangeProcessAction(store, payload) {
    try {
      store.commit('UPDATE_TIMEBASE_CHANGE_PROCESS', null)
      const response = await axios.get(
        `${BASE}/es/api/v2/laborConditionManager/timebase/` +
          payload.processUuid,
      )
      store.commit('UPDATE_TIMEBASE_CHANGE_PROCESS', response.data)
      return response
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/laborConditionManager/timebase/` +
          payload.processUuid,
      )
    }
  },

  // /////
  // POST
  // /////

  // UPDATE LABOR CONDITION
  // POST
  async updateLaborConditionDataAction({ store }, payload) {
    let path
    switch (payload.role) {
      case 'hr':
        path = 'laborDataByHr'
        break
      case 'director':
      case 'organizer':
        path = 'laborDataByDirector'
        break
      case 'responsible':
        path = 'laborDataByResponsible'
        break
      case 'collaborator':
        path = 'laborData'
        break
    }
    try {
      return await axios.post(
        `${BASE}/es/api/v2/collaborators/edit.${path}`,
        payload.command,
      )
    } catch (e) {
      throw Error(`API Error occurred at /es/api/v2/collaborators/edit.${path}`)
    }
  },
  // TIMEBASE CHANGE
  // POST
  async startTimebaseChangeAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/laborConditionManager/timebase.startTimebaseChangeProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/laborConditionManager/timebase.startTimebaseChangeProcess`,
      )
    }
  },
  // SIGN ON PAPER TIMEBASE CHANGE
  // POST
  async signOnPaperTimebaseChangeProcessAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/laborConditionManager/timebase.signOnPaperTimebaseChangeProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/laborConditionManager/timebase.signOnPaperTimebaseChangeProcess`,
      )
    }
  },
  // CANCELL TIMEBASE CHANGE PROCESS
  // POST
  async cancelTimebaseChangeProcessAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/laborConditionManager/timebase.cancelTimebaseChangeProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/laborConditionManager/timebase.cancelTimebaseChangeProcess`,
      )
    }
  },
  // UPDATE TIMEBASE CHANGE PROCESS
  // POST
  async updateTimebaseChangeProcessAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/laborConditionManager/timebase.updateTimeBaseChangeProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/laborConditionManager/timebase.updateTimeBaseChangeProcess`,
      )
    }
  },
  // START SHIFT REDUCTION
  // POST
  async startShiftReductionAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/laborConditionManager/reduction.startShiftReductionProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/laborConditionManager/reduction.startShiftReductionProcess`,
      )
    }
  },
  // SIGN ON PAPER SHIFT REDUCTION
  // POST
  async signOnPaperShiftReductionProcessAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/laborConditionManager/reduction.signOnPaperShiftReductionProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/laborConditionManager/reduction.signOnPaperShiftReductionProcess`,
      )
    }
  },
  // CANCEL SHIFT REDUCTION PROCESS
  // POST
  async cancelShiftReductionProcessAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/laborConditionManager/reduction.cancelShiftReductionProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/laborConditionManager/reduction.cancelShiftReductionProcess`,
      )
    }
  },
  // END SHIFT REDUCTION PROCESS
  // POST
  async endShiftReductionProcessAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/laborConditionManager/reduction.endShiftReductionProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/laborConditionManager/reduction.endShiftReductionProcess`,
      )
    }
  },
  // END SHIFT REDUCTION PROCESS
  // POST
  async updateShiftReductionProcessAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/laborConditionManager/reduction.updateShiftReductionProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/laborConditionManager/reduction.updateShiftReductionProcess`,
      )
    }
  },
  // START COMPLEMENTARY PACT
  // POST
  async startComplementaryPactAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/laborConditionManager/compPact.startComplementaryPactProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/laborConditionManager/compPact.startComplementaryPactProcess`,
      )
    }
  },
  // SEND COMPLEMENTARY PACT
  // POST
  async sendComplementaryPactDocumentAction(store, payload) {
    const url = `${BASE}/es/api/v2/laborConditionManager/common.sendLaborConditionProcess`
    try {
      return await axios.post(url, payload)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },
  // CANCEL COMPLEMENTARY PACT
  // POST
  async cancelComplementaryPactAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/laborConditionManager/compPact.cancelComplementaryPactProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/laborConditionManager/compPact.cancelComplementaryPactProcess`,
      )
    }
  },
  // SIGN ON PAPER COMPLEMENTARY PACT
  // POST
  async signOnPaperComplementaryPactAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/laborConditionManager/compPact.signOnPaperComplementaryPactProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/laborConditionManager/compPact.signOnPaperComplementaryPactProcess`,
      )
    }
  },
  // UPDATE MANAGED HOUR CHANGE PROCESSES
  // POST
  async updateManagedHourChangeProcessesAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/laborConditionManager/process.updateSapTimebaseChangeProcessManagement`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/laborConditionManager/process.updateSapTimebaseChangeProcessManagement.`,
      )
    }
  },
  // UPDATE COMPLEMENTARY PACT PROCESS
  // POST
  async updateComplementaryPactProcessAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/laborConditionManager/compPact.updateComplementaryPactProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/laborConditionManager/compPact.updateComplementaryPactProcess`,
      )
    }
  },
  // SAP HOUR CHANGE RECORDS
  // POST
  async handleSapHourChangeRecords(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/process.exportSapData`,
        payload,
      )
    } catch (error) {
      throw new Error(`API Error occurred in /es/api/v2/process.exportSapData`)
    }
  },

  // SEND DOCUMENT TO SIGNER PROVIDER
  // POST
  async sendTimebaseChangeDocumentToSignerProviderAction(store, payload) {
    const url = `${BASE}/es/api/v2/laborConditionManager/timebase.sendTimebaseChangeProcessDocument`
    try {
      return await axios.post(url, payload)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },

  // SIGNATURE PENDING CONTRACT PROCESSES LABOR CONDITION
  // GET
  async fetchSignaturePendingContractLaborCondition(store) {
    try {
      store.commit('UPDATE_PENDING_CONTRACTS_PROCESSES_LABOR_CONDITION', null)
      const res = await axios.get(
        `${BASE}/es/api/v2/laborConditionManager/collaborator.signaturePendingLaborConditionProcesses`,
      )
      store.commit(
        'UPDATE_PENDING_CONTRACTS_PROCESSES_LABOR_CONDITION',
        res.data,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/es/api/v2/laborConditionManager/collaborator.signaturePendingLaborConditionProcesses`,
      )
    }
  },
}

function joinFilters(filters) {
  const filtersArr = []
  Object.keys(filters).forEach((key) => {
    if (filters[key] !== null || filters[key] !== undefined) {
      filtersArr.push(`filter[${key}]=${filters[key]}`)
    }
  })
  return filtersArr
}

function sortPositions(positions) {
  return positions.sort((x, y) => {
    return +x.id === 2 || +x.id === 10
      ? -1
      : +y.id === 2 || +y.id === 10
      ? 1
      : 0
  })
}
