import { urlQueryStringBuilder } from '@utils/constantHelpers'
import axios from '@utils/axiosInstance'
import sortOptionsByKey from '@utils/sortOptionsByKey'
import queryStringFilterBuilder from '@utils/queryStringFilterBuilder'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    collaborator: null,
    collaborators: [],
    collaboratorsSiteManagers: [],
    fictitious: null,
    siteFictitious: [],
    teamCollaborators: null,
    deletedCollaborators: [],
    collaboratorsWithEndingContracts: [],
    civilStatus: [],
    genders: [],
    studies: [],
    sportDisciplines: [],
    sportDisciplineLevels: [],
    sportDisciplineFrequencies: [],
    collaboratorMutationProcesses: null,
    pendingSapIbanChangeProcesses: [],
    pendingSapAddressChangeProcesses: [],
    dataExtraction: [],
    substitutedCollaborators: [],
    // forthcomingContractEndResponsible: [],
    // forthcomingContractEndDirector: {},
    forthcomingContractEnd: {},
    collaboratorsBasicData: [],
    collaboratorsPlanningMonthSelection: [],
    collaboratorsRebillingSelection: [],
    collaboratorsCdiDfToBeCalled: [],
    applicableReactivationActions: [],
    teamsCoherenceReport: [],
    collaboratorsEffectiveRestSelection: [],
    absenteeisms: [],
    patrons: [],
  }
}
export const state = getDefaultState()

export const getters = {}

export const mutations = {
  UPDATE_COLLABORATOR(state, collaborator) {
    state.collaborator = collaborator
  },
  UPDATE_COLLABORATORS(state, collaborators) {
    state.collaborators = collaborators
  },
  UPDATE_COLLABORATORS_SITE_MANAGERS(state, collaboratorsSiteManagers) {
    state.collaboratorsSiteManagers = collaboratorsSiteManagers
  },
  UPDATE_FICTITIOUS(state, fictitious) {
    state.fictitious = fictitious
  },
  UPDATE_SITE_FICTITIOUS(state, siteFictitious) {
    state.siteFictitious = siteFictitious
  },
  UPDATE_TEAM_COLLABORATORS(state, teamCollaborators) {
    state.teamCollaborators = teamCollaborators
  },
  UPDATE_DELETED_COLLABORATORS(state, deletedCollaborators) {
    state.deletedCollaborators = deletedCollaborators
  },
  UPDATE_COLLABORATORS_WITH_ENDING_CONTRACTS(
    state,
    collaboratorsWithEndingContracts,
  ) {
    state.collaboratorsWithEndingContracts = collaboratorsWithEndingContracts
  },
  UPDATE_CIVIL_STATUS(state, civilStatus) {
    state.civilStatus = civilStatus
  },
  UPDATE_GENDERS(state, genders) {
    state.genders = genders
  },
  UPDATE_STUDIES(state, studies) {
    state.studies = studies
  },
  UPDATE_SPORT_DISCIPLINES(state, sportDisciplines) {
    state.sportDisciplines = sportDisciplines
  },
  UPDATE_SPORT_DISCIPLINE_LEVELS(state, sportDisciplineLevels) {
    state.sportDisciplineLevels = sportDisciplineLevels
  },
  UPDATE_SPORT_DISCIPLINE_FREQUENCIES(state, sportDisciplineFrequencies) {
    state.sportDisciplineFrequencies = sportDisciplineFrequencies
  },
  UPDATE_COLLABORATOR_MUTATION_PROCESSES(state, collaboratorMutationProcesses) {
    state.collaboratorMutationProcesses = collaboratorMutationProcesses
  },
  UPDATE_PENDING_SAP_IBAN_CHANGE_PROCESSES(
    state,
    pendingSapIbanChangeProcesses,
  ) {
    state.pendingSapIbanChangeProcesses = pendingSapIbanChangeProcesses
  },
  UPDATE_PENDING_SAP_ADDRESS_CHANGE_PROCESSES(
    state,
    pendingSapAddressChangeProcesses,
  ) {
    state.pendingSapAddressChangeProcesses = pendingSapAddressChangeProcesses
  },
  UPDATE_DATA_EXTRACTION(state, dataExtraction) {
    state.dataExtraction = dataExtraction
  },
  RESET_COLLABORATOR_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  UPDATE_SUBSTITUTED_COLLABORATORS(state, substitutedCollaborators) {
    substitutedCollaborators.forEach((item) => {
      item.fullName = `${item.name} ${item.firstLastName} ${item.secondLastName}`
    })

    sortOptionsByKey(substitutedCollaborators, 'fullName')
    state.substitutedCollaborators = substitutedCollaborators
  },
  UPDATE_FORTH_COMING_CONTRACT_END(state, forthcomingContractEnd) {
    state.forthcomingContractEnd = forthcomingContractEnd
  },
  UPDATE_COLLABORATORS_BASIC_DATA(state, collaboratorsBasicData) {
    state.collaboratorsBasicData = collaboratorsBasicData
  },
  UPDATE_COLLABORATORS_MONTH_SELECTION(
    state,
    collaboratorsPlanningMonthSelection,
  ) {
    state.collaboratorsPlanningMonthSelection =
      collaboratorsPlanningMonthSelection
  },
  UPDATE_COLLABORATORS_REBILLING_SELECTION(
    state,
    collaboratorsRebillingSelection,
  ) {
    state.collaboratorsRebillingSelection = collaboratorsRebillingSelection
  },
  UPDATE_COLLABORATORS_CDIDF_TO_BE_CALLED(state, collaboratorsCdiDfToBeCalled) {
    state.collaboratorsCdiDfToBeCalled = collaboratorsCdiDfToBeCalled
  },
  UPDATE_APPLICABLE_REACTIVATION_ACTIONS(state, applicableReactivationActions) {
    state.applicableReactivationActions = applicableReactivationActions
  },
  UPDATE_TEAMS_COHERENCE_REPORT(state, teamsCoherenceReport) {
    state.teamsCoherenceReport = teamsCoherenceReport
  },
  UPDATE_COLLABORATORS_EFFECTIVE_REST_SELECTION(
    state,
    collaboratorsEffectiveRestSelection,
  ) {
    state.collaboratorsEffectiveRestSelection =
      collaboratorsEffectiveRestSelection
  },
  UPDATE_ABSENTEEISMS(state, absenteeisms) {
    state.absenteeisms = absenteeisms
  },
  UPDATE_PATRONS(state, patrons) {
    state.patrons = patrons
  },
  REMOVE_COLLABORATOR_FROM_PATRONS_LIST(state, collaboratorUuidToRemove) {
    state.patrons = state.patrons.filter(
      (item) => item.uuid !== collaboratorUuidToRemove,
    )
  },
}

export const actions = {
  // COLLABORATOR BY UUID
  // GET
  async fetchCollaboratorByUuid(store, collaboratorUuid) {
    store.commit('UPDATE_COLLABORATOR', null)
    try {
      const response = await axios.get(
        `${BASE}/es/api/v2/collaborators/${collaboratorUuid}`,
      )
      store.commit('UPDATE_COLLABORATOR', response.data)
      return response
    } catch (error) {
      throw new Error(
        `API Error occurred at /api/collaborators/${collaboratorUuid}.`,
      )
    }
  },
  // COLLABORATORS
  // GET
  async fetchCollaborators(store, payload) {
    store.commit('UPDATE_COLLABORATORS', [])
    let queryString = ''
    if (payload.key) {
      queryString = encodeURI(
        queryString + `?filter[${payload.key}]=${payload.value}`,
      )
    }

    const url = `${BASE}/es/api/v2/collaborators${queryString}`
    try {
      const response = await axios.get(url)
      store.commit('UPDATE_COLLABORATORS', response.data)
      return response
    } catch (error) {
      throw new Error(`API Error occurred at api/collaborators.`)
    }
  },
  // COLLABORATORS SITE
  // GET
  async fetchCollaboratorsSite(store, filters) {
    store.commit('UPDATE_COLLABORATORS', [])
    try {
      const response = await axios.get(
        `${BASE}/es/api/v2/sites/${filters.personnelDivision}/collaborators`,
      )
      store.commit('UPDATE_COLLABORATORS', response.data)
    } catch (error) {
      throw new Error(
        `API Error occurred at ${BASE}/es/api/v2/sites/${filters.personnelDivision}/collaborators`,
      )
    }
  },
  async fetchCollaboratorsForMonthPlanningSelectAction(store, filters) {
    store.commit('UPDATE_COLLABORATORS_MONTH_SELECTION', [])
    try {
      const response = await axios.get(
        `${BASE}/es/api/v2/sites/${filters.personnelDivision}/collaboratorsPlanningMonthSelection`,
      )
      store.commit('UPDATE_COLLABORATORS_MONTH_SELECTION', response.data)
    } catch (error) {
      throw new Error(
        `API Error occurred at ${BASE}/es/api/v2/sites/${filters.personnelDivision}/collaboratorsPlanningMonthSelection`,
      )
    }
  },

  async fetchCollaboratorsForRebillingSelectAction(store, filters) {
    store.commit('UPDATE_COLLABORATORS_REBILLING_SELECTION', [])
    try {
      const response = await axios.get(
        `${BASE}/es/api/v2/sites/${filters.personnelDivision}/collaboratorsRebillingSelection`,
      )
      store.commit('UPDATE_COLLABORATORS_REBILLING_SELECTION', response.data)
    } catch (error) {
      throw new Error(
        `API Error occurred at ${BASE}/es/api/v2/sites/${filters.personnelDivision}/collaboratorsRebillingSelection`,
      )
    }
  },

  // GET
  async fetchCollaboratorsSiteManagersAction(store) {
    try {
      const response = await axios.get(
        `${BASE}/es/api/v2/collaborators/siteManagersRoles`,
      )
      store.commit('UPDATE_COLLABORATORS_SITE_MANAGERS', response.data)
    } catch (error) {
      throw new Error(
        `API Error occurred at ${BASE}/es/api/v2/collaborators/siteManagersRoles`,
      )
    }
  },
  // FICTITIOUS
  // GET
  async fetchFictitiousAction(store, uuid) {
    store.commit('UPDATE_FICTITIOUS', null)
    try {
      const response = await axios.get(`${BASE}/es/api/v2/fictitious/${uuid}`)
      store.commit('UPDATE_FICTITIOUS', response.data)
      return response
    } catch (error) {
      throw new Error(
        `API Error occurred at /es/api/v2/fictitious/${uuid}: ${error}`,
      )
    }
  },
  // SITE FICTITIOUS
  // GET
  async fetchSiteFictitiousAction(store, fictitiousUuid) {
    store.commit('UPDATE_SITE_FICTITIOUS', [])
    const url = `${BASE}/es/api/v2/sites/${fictitiousUuid}/fictitious`
    try {
      const response = await axios.get(url)
      store.commit('UPDATE_SITE_FICTITIOUS', response.data)
    } catch (error) {
      throw new Error(
        `API Error occurred at /es/api/v2/sites/${fictitiousUuid}/fictitious: ${error}`,
      )
    }
  },
  // TEAM COLLABORATORS & FICTITIOUS
  // GET
  async fetchTeamCollaboratorsAction(store, teamUuid) {
    store.commit('UPDATE_TEAM_COLLABORATORS', null)
    try {
      const response = await axios.get(
        `${BASE}/es/api/v2/getCollaboratorsByTeam/${teamUuid}`,
      )
      store.commit('UPDATE_TEAM_COLLABORATORS', response.data)
    } catch (error) {
      throw new Error(
        `API Error occurred at ${BASE}/es/api/v2/getCollaboratorsByTeam/${teamUuid}: ${error}`,
      )
    }
  },
  // TEAM COLLABORATORS & FICTITIOUS
  // GET
  async fetchTeamCollaboratorsWithPlanningAction(store, filters) {
    store.commit('UPDATE_TEAM_COLLABORATORS', null)

    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    try {
      const response = await axios.get(
        `${BASE}/es/planning/api/v2/PlanningManager/getCollaboratorsByTeamWithPlanning${queryString}`,
      )
      store.commit('UPDATE_TEAM_COLLABORATORS', response.data)

      return response
    } catch (error) {
      throw new Error(
        `API Error occurred at ${BASE}/es/Planning/api/v2/planningManager/getCollaboratorsByTeamWithPlanning${queryString}: ${error}`,
      )
    }
  },
  // DELETED COLLABORATORS
  // GET
  async fetchDeletedCollaborators(store, payload) {
    store.commit('UPDATE_DELETED_COLLABORATORS', [])

    const url = `${BASE}/es/api/v2/contracts/collaborators.deleted${urlQueryStringBuilder(
      payload,
    )}`
    try {
      const response = await axios.get(url)
      store.commit('UPDATE_DELETED_COLLABORATORS', response.data)

      return response.data
    } catch (error) {
      throw new Error(
        `API Error occurred at /es/api/v2/contracts/collaborators.deleted${urlQueryStringBuilder(
          payload,
        )}`,
      )
    }
  },
  // COLLABORATORS WITH ENDING CONTRACTS
  // GET
  async fetchCollaboratorsWithEndingContractsBySite(store, payload) {
    store.commit('UPDATE_COLLABORATORS_WITH_ENDING_CONTRACTS', [])
    try {
      const response = await axios.get(
        `${BASE}/es/api/v2/sites/${payload.personnelDivision}/collaborators/forthcomingContractEnd?filter[daysToCheck]=${payload.daysToCheck}`,
      )
      store.commit('UPDATE_COLLABORATORS_WITH_ENDING_CONTRACTS', response.data)
    } catch (error) {
      throw new Error(
        `API Error occurred at ${BASE}/es/api/v2/sites/${payload.personnelDivision}/collaborators/forthcomingContractEnd${payload.daysToCheck}`,
      )
    }
  },

  async fetchCollaboratorsWithEndingContractsBySiteAndTeam(store, payload) {
    store.commit('UPDATE_COLLABORATORS_WITH_ENDING_CONTRACTS', [])
    try {
      const response = await axios.get(
        `${BASE}/es/api/v2/sites/${payload.personnelDivision}/team/${payload.teamUuid}/collaborators/forthcomingContractEnd`,
      )
      store.commit('UPDATE_COLLABORATORS_WITH_ENDING_CONTRACTS', response.data)
    } catch (error) {
      throw new Error(
        `API Error occurred at ${BASE}/es/api/v2/sites/${payload.personnelDivision}/team/${payload.teamUuid}/collaborators/forthcomingContractEnd`,
      )
    }
  },
  // CIVIL STATUS
  // GET
  async fetchCivilStatus(store) {
    const url = `${BASE}/es/api/v2/civilStatuses`
    try {
      const response = await axios.get(url)
      store.commit('UPDATE_CIVIL_STATUS', response.data)
      return response
    } catch (error) {
      throw new Error('API Error occurred at es/api/v2/civilStatuses.')
    }
  },
  // GENDERS
  // GET
  async fetchGenders(store) {
    const url = `${BASE}/es/api/v2/genders`
    try {
      const response = await axios.get(url)
      store.commit('UPDATE_GENDERS', response.data)
    } catch (error) {
      throw new Error('API Error occurred at es/api/v2/genders.')
    }
  },
  // STUDIES
  // GET
  async fetchStudies(store) {
    const url = `${BASE}/es/api/v2/educationTypes`
    try {
      const response = await axios.get(url)
      store.commit('UPDATE_STUDIES', response.data)
    } catch (error) {
      throw new Error('API Error occurred at es/api/v2/educationTypes.')
    }
  },
  // GET ALL
  async fetchSportDisciplines(store) {
    const url = `${BASE}/es/api/v2/sportDisciplines`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_SPORT_DISCIPLINES', res.data)
    } catch (e) {
      throw Error(`API Error occurred in ${url}`)
    }
  },
  // SPORT DISCIPLINES LEVEL
  // GET ALL
  async fetchSportDisciplineLevels(store) {
    const url = `${BASE}/es/api/v2/sportDisciplineLevels`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_SPORT_DISCIPLINE_LEVELS', res.data)
    } catch (e) {
      throw Error(`API Error occurred in ${url}`)
    }
  },
  // SPORT DISCIPLINES FREQUENCY
  // GET ALL
  async fetchSportDisciplineFrequencies(store) {
    const url = `${BASE}/es/api/v2/sportDisciplineFrequencies`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_SPORT_DISCIPLINE_FREQUENCIES', res.data)
    } catch (e) {
      throw Error(`API Error occurred in ${url}`)
    }
  },

  // FETCH COLLABORATOR MUTATION PROCESSES
  // GET
  async fetchCollaboratorMutationProcesses(store, filters) {
    store.commit('UPDATE_COLLABORATOR_MUTATION_PROCESSES', null)
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''

    const url = `${BASE}/es/api/v2/mutationsProcess${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_COLLABORATOR_MUTATION_PROCESSES', res.data)
    } catch (e) {
      throw Error(`API Error occurred in ${url}`)
    }
  },
  // FETCH SITE COLLABORATOR MUTATION PROCESSES
  // GET
  async fetchCollaboratorMutationProcessesSite(store, site) {
    store.commit('UPDATE_COLLABORATOR_MUTATION_PROCESSES', null)

    const url = `${BASE}/es/api/v2/sites/${site}/mutationsProcess`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_COLLABORATOR_MUTATION_PROCESSES', res.data)
    } catch (e) {
      throw Error(`API Error occurred in ${url}`)
    }
  },
  // FETCH PENDING SAP IBAN CHANGE PROCESSES
  // GET
  async fetchPendingSapIbanChangeProcesses(store, filters = {}) {
    store.commit('UPDATE_PENDING_SAP_IBAN_CHANGE_PROCESSES', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key] !== null) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/es/api/v2/collaborators/iban.switching${queryString}`
    try {
      const response = await axios.get(url)
      store.commit('UPDATE_PENDING_SAP_IBAN_CHANGE_PROCESSES', response.data)
      return response.data
    } catch (e) {
      throw Error(`API Error occurred in ${url}`)
    }
  },
  // FETCH PENDING SAP ADDRESS CHANGE PROCESSES
  // GET
  async fetchPendingSapAddressChangeProcesses(store, filters = {}) {
    store.commit('UPDATE_PENDING_SAP_ADDRESS_CHANGE_PROCESSES', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key] !== null) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/es/api/v2/collaborators/address.switching${queryString}`
    try {
      const response = await axios.get(url)
      store.commit('UPDATE_PENDING_SAP_ADDRESS_CHANGE_PROCESSES', response.data)
      return response.data
    } catch (e) {
      throw Error(`API Error occurred in ${url}`)
    }
  },
  // /////////
  // POST
  // /////////

  // UPDATE COLLABORATOR PERSONAL DATA
  // POST
  async updateCollaboratorPersonalDataAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/collaborators/edit.personalData`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/collaborators/edit.personalData`,
      )
    }
  },
  // UPDATE COLLABORATOR SENSITIVE PERSONAL DATA
  // POST
  async updateCollaboratorSensitivePersonalDataAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/collaborators/edit.sensitivePersonalData`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/collaborators/edit.sensitivePersonalData`,
      )
    }
  },
  // UPDATE COLLABORATOR LEGAL TUTOR DATA
  // POST
  async updateLegalTutorDataAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/collaborators/edit.legalTutorData`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/collaborators/edit.legalTutorData`,
      )
    }
  },
  // UPDATE COLLABORATOR ABILITY DATA
  // POST
  async updateAbilityDataAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/collaborators/edit.personalAbilitiesData`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/collaborators/edit.personalAbilitiesData`,
      )
    }
  },
  // START COLLABORATOR MUTATION PROCESS
  // POST
  async collaboratorMutationAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/mutation.startMutationProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/mutation.startMutationProcess`,
      )
    }
  },
  // CANCEL MUTATION PROCESS
  // POST
  async cancelMutationContractProcessAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/mutation.cancelMutationProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/mutation.cancelMutationProcess`,
      )
    }
  },
  // UPDATE MANAGED IBAN CHANGE PROCESSES
  // POST
  async updateManagedIbanChangeProcessesAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/iban.switching/updateSapProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/iban.switching/updateSapProcess.`,
      )
    }
  },
  // UPDATE MANAGED ADDRESS CHANGE PROCESSES
  // POST
  async updateManagedAddressChangeProcessesAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/address.switching/updateSapProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/address.switching/updateSapProcess.`,
      )
    }
  },
  // FICTITIOUS CREATE
  // POST
  async hireFictitiousAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/fictitious/create`,
        payload,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in /es/api/v2/contractManager/fictitious/create`,
      )
    }
  },
  // FICTITIOUS UPDATE
  // POST
  async updateFictitiousAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/fictitious/edit`,
        payload,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in /es/api/v2/contractManager/fictitious/edit`,
      )
    }
  },
  // FICTITIOUS DELETE
  // POST
  async deleteFictitiousAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/fictitious/delete`,
        payload,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in /es/api/v2/contractManager/fictitious/delete`,
      )
    }
  },
  // COLLABORATORS EXTRACTION
  async fetchCollaboratorsExtractionAction(store, filters) {
    store.commit('UPDATE_DATA_EXTRACTION', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/es/api/v2/collaborators/dataExtraction${queryString}`
    try {
      const response = await axios.get(url)
      store.commit('UPDATE_DATA_EXTRACTION', response.data)

      return response.data
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },

  // FETCH SUBSTITUTED COLLABORATORS
  // GET
  async fetchSubstitutedCollaboratorsAction(store, filters) {
    // Filters: [personnelDivision]
    store.commit('UPDATE_SUBSTITUTED_COLLABORATORS', [])
    const url = `${BASE}/es/api/v2/substitutableCollaborators/${filters.personnelDivision}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_SUBSTITUTED_COLLABORATORS', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },
  // FETCH forthcomingContractEnd Home
  // GET
  async fetchForthcomingContractEnd(store, params) {
    store.commit('UPDATE_FORTH_COMING_CONTRACT_END', [])
    const url = `${BASE}/es/api/v2/sites/${params.personnelDivision}/collaborators/forthcomingContractEnd`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_FORTH_COMING_CONTRACT_END', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },
  // FETCH collaborators.basicData
  // GET
  async fetchCollaboratorsBasicData(store, personnelDivision) {
    store.commit('UPDATE_COLLABORATORS_BASIC_DATA', [])
    const url = `${BASE}/es/api/v2/sites/${personnelDivision}/collaborators.basicData`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_COLLABORATORS_BASIC_DATA', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },
  // START CREATE COLLABORATOR TEAM CHANGE PROCESS
  // POST
  async collaboratorTeamChangeAction({ store }, payload) {
    try {
      return await axios.post(`${BASE}/es/api/v2/team/change/create`, payload)
    } catch (e) {
      throw Error(`API Error occurred at /es/api/v2/team/change/create`)
    }
  },
  // POST
  async updateCollaboratorTeamChangeAction({ store }, payload) {
    try {
      return await axios.post(`${BASE}/es/api/v2/team/change/update`, payload)
    } catch (e) {
      throw Error(`API Error occurred at /es/api/v2/team/change/update`)
    }
  },
  // CREATE COLLABORATOR IDENTITY ACCESS
  // POST
  async createCollaboratorIdentityAccessAction(store, payload) {
    const url = `${BASE}/es/api/v2/collaborators/createIdentityAccessProfile`
    try {
      return await axios.post(url, payload)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },
  // COLLABORATORS EXTRACTION
  async fetchCollaboratorsCDIDFToBeCalled(store, filters) {
    store.commit('UPDATE_COLLABORATORS_CDIDF_TO_BE_CALLED', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/es/api/v2/collaborators/cdiDfToBeCalled${queryString}`
    try {
      const res = await axios.get(url)

      store.commit('UPDATE_COLLABORATORS_CDIDF_TO_BE_CALLED', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },
  // APPLICABLE REACTIVATION ACTIONS
  // GET
  async fetchApplicableReactivationActionsAction(store, collaboratorUuid) {
    store.commit('UPDATE_APPLICABLE_REACTIVATION_ACTIONS', [])
    const url = `${BASE}/es/api/v2/reactivationActionsForDeletedCollaborator/${collaboratorUuid}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_APPLICABLE_REACTIVATION_ACTIONS', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },

  // ONLY UNDO LEAVE EXECUTED BY MISTAKE ACTION
  // POST
  async undoLeaveExecutedByMistakeAction(store, payload) {
    const url = `${BASE}/es/api/v2/undoLeaveExecutedByMistake`
    try {
      const res = await axios.post(url, payload)
      return res
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },
  // UNDO LEAVE EXECUTED BY MISTAKE AND EDIT CONTRACT END DATE ACTION
  // POST
  async undoLeaveExecutedByMistakeAndEditContractEndDateAction(store, payload) {
    const url = `${BASE}/es/api/v2/undoLeaveExecutedByMistakeAndEditContractEndDate`
    try {
      const res = await axios.post(url, payload)
      return res
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },
  // UNDO LEAVE EXECUTED BY MISTAKE AND START CONTRACT EXTENSION ACTION
  // POST
  async undoLeaveExecutedByMistakeAndStartContractExtensionAction(
    store,
    payload,
  ) {
    const url = `${BASE}/es/api/v2/undoLeaveExecutedByMistakeAndStartContractExtension`
    try {
      const res = await axios.post(url, payload)
      return res
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },
  // UNDO LEAVE EXECUTED BY MISTAKE AND START CDI CONVERSION ACTION
  // POST
  async undoLeaveExecutedByMistakeAndStartCdiConversionAction(store, payload) {
    const url = `${BASE}/es/api/v2/undoLeaveExecutedByMistakeAndStartCdiConversion`
    try {
      const res = await axios.post(url, payload)
      return res
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },
  // UNDO LEAVE EXECUTED BY MISTAKE AND START CDI DF CONVERSION ACTION
  async startConversionCdiDfWithReactivationAction(store, payload) {
    const url = `${BASE}/es/api/v2/undoLeaveExecutedByMistakeAndStartCdiDfConversion`
    try {
      const res = await axios.post(url, payload)
      return res
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },
  async fetchTeamsCoherenceReportAction(store, filters) {
    store.commit('UPDATE_TEAMS_COHERENCE_REPORT', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/es/api/v2/teamsCoherenceReport${queryString}`
    try {
      const res = await axios.get(url)

      store.commit('UPDATE_TEAMS_COHERENCE_REPORT', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },
  async fetchCollaboratorsForEffectiveRestSelectAction(store, filters) {
    store.commit('UPDATE_COLLABORATORS_EFFECTIVE_REST_SELECTION', [])
    try {
      const response = await axios.get(
        `${BASE}/es/api/v2/sites/${filters.personnelDivision}/collaboratorsEffectiveRestSelection`,
      )
      store.commit(
        'UPDATE_COLLABORATORS_EFFECTIVE_REST_SELECTION',
        response.data,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred at ${BASE}/es/api/v2/sites/${filters.personnelDivision}/collaboratorsEffectiveRestSelection`,
      )
    }
  },
  // LOAD ABSENTEEISMS
  // POST
  async loadAbsenteeismsAction(store, payload) {
    try {
      const response = await axios.post(
        `${BASE}/es/api/v2/absenteeism.dataloading`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      return response
    } catch (error) {
      throw new Error(
        `API Error occurred at ${BASE}/es/api/v2/absenteeism.dataloading`,
      )
    }
  },
  // FETCH ABSENTEEISMS
  // GET
  async fetchAbsenteeismsAction(store, filter) {
    const queryString = queryStringFilterBuilder(filter)
    store.commit('UPDATE_ABSENTEEISMS', [])
    try {
      const response = await axios.get(
        `${BASE}/es/api/v2/absenteeism${queryString}`,
      )
      store.commit('UPDATE_ABSENTEEISMS', response.data)
      return response
    } catch (error) {
      throw new Error(`API Error occurred at ${BASE}/es/api/v2/absenteeism`)
    }
  },

  // FETCH PATRONS FOR COLLABORATOR LEVEL
  // GET
  async fetchTeamPatronsAction(store, teamUuid) {
    try {
      store.commit('UPDATE_PATRONS', [])
      const res = await axios.get(
        `${BASE}/es/api/v2/collaborators/patronsForCollaboratorLevel/${teamUuid}`,
      )
      store.commit('UPDATE_PATRONS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred. ${BASE}/es/api/v2/collaborators/patronsForCollaboratorLevel/${teamUuid}`,
      )
    }
  },
  // FETCH PATRONS FOR RESPONSIBLE LEVEL
  // GET
  async fetchResponsiblePatronAction(store, teamUuid) {
    try {
      store.commit('UPDATE_PATRONS', [])
      const res = await axios.get(
        `${BASE}/es/api/v2/collaborators/patronsForResponsibleLevel/${teamUuid}`,
      )
      store.commit('UPDATE_PATRONS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/es/api/v2/collaborators/patronsForResponsibleLevel/${teamUuid}`,
      )
    }
  },
  // FETCH PATRONS FOR DIRECTOR LEVEL
  // GET
  async fetchDirectorPatronAction(store, teamUuid) {
    try {
      store.commit('UPDATE_PATRONS', [])
      const res = await axios.get(
        `${BASE}/es/api/v2/collaborators/patronsForDirectorLevel/${teamUuid}`,
      )
      store.commit('UPDATE_PATRONS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/es/api/v2/collaborators/patronsForDirectorLevel/${teamUuid} ${error}`,
      )
    }
  },

  async fetchCollaboratorVoluntaryResignationList(store, payload) {
    const url = `${BASE}/es/api/v2/contractManager/selfManagedVoluntaryLeave`
    try {
      const response = await axios.get(url, payload)
      return response.data
    } catch (error) {
      throw new Error(`API Error occurred in ${error}`)
    }
  },

  async startCollaboratorVoluntaryResignation(store, payload) {
    const url = `${BASE}/es/api/v2/contractManager/selfManagedVoluntaryLeave.start`
    try {
      const response = await axios.post(url, payload)
      return response.data
    } catch (error) {
      throw new Error(`API Error occurred in ${error}`)
    }
  },

  async fetchCollaboratorDocumentVoluntaryResignation(store, processUuid) {
    const url = `${BASE}/es/api/v2/contractManager/selfManagedVoluntaryLeaveDocument/${processUuid}`
    try {
      const response = await axios.get(url)
      return response.data
    } catch (error) {
      throw new Error(`API Error occurred in ${error}`)
    }
  },

  async createTokenValidation(store, payload) {
    const url = `${BASE}/es/api/v2/contractManager/selfManagedVoluntaryLeave.createToken`
    try {
      const response = await axios.post(url, payload)
      return response.data
    } catch (error) {
      throw new Error(`API Error occurred in ${error}`)
    }
  },

  async confirmTokenValidation(store, payload) {
    const url = `${BASE}/es/api/v2/contractManager/selfManagedVoluntaryLeave.validate`
    try {
      const response = await axios.post(url, payload)
      return response.data
    } catch (error) {
      throw new Error(`API Error occurred in ${error}`)
    }
  },

  async cancelVoluntaryResignationWithTokenValidation(store, payload) {
    const url = `${BASE}/es/api/v2/contractManager/selfManagedVoluntaryLeave.startCancellation`
    try {
      const response = await axios.post(url, payload)
      return response.data
    } catch (error) {
      throw new Error(`API Error occurred in ${error}`)
    }
  },

  async cancelVoluntaryResignation(store, payload) {
    const url = `${BASE}/es/api/v2/contractManager/selfManagedVoluntaryLeave.cancel`
    try {
      const response = await axios.post(url, payload)
      return response.data
    } catch (error) {
      throw new Error(`API Error occurred in ${error}`)
    }
  },

  async fetchProcessesHistoryByCollaboratorUuid(store, collaboratorUuid) {
    try {
      return await axios.get(
        `${BASE}/es/api/v2/processesHistory/${collaboratorUuid}`,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/es/api/v2/processesHistory/${collaboratorUuid} ${error}`,
      )
    }
  },
}
