import store from '@state/store'
import { siteClearance } from '@utils/clearanceHelpers'
import planningRoutes from './planningRoutes'

export default [
  ...planningRoutes,
  {
    path: '/',
    beforeResolve: (to, from, next) => {
      store.dispatch('collaborator/RESET_COLLABORATOR_STATE()')
      next() // decide view to redirect when user access directly without verification
    },
    redirect: (to) => {
      const actorRoles = store.state.auth.actorRoles
      if (!actorRoles) return '/logout'

      const site = []
      // eslint-disable-next-line array-callback-return
      actorRoles.map((e) => {
        if (e.site && !site.includes(e.site.personnelDivision)) {
          site.push(e.site.personnelDivision)
        }
      })

      const isAuthorized = (rolesArr, clearanceArr) => {
        return rolesArr.some((element) => {
          return clearanceArr.some((e) => e === element.role.code)
        })
      }
      if (
        isAuthorized(actorRoles, siteClearance.admin) ||
        isAuthorized(actorRoles, siteClearance.directorCoach) ||
        isAuthorized(actorRoles, siteClearance.multicenterLeader)
      ) {
        return { name: 'spain-map' }
      } else if (isAuthorized(actorRoles, siteClearance.lawyer)) {
        return {
          name: 'home-legal',
          params: { personnelDivision: site[0] },
        }
      } else if (
        isAuthorized(actorRoles, siteClearance.director) ||
        isAuthorized(actorRoles, siteClearance.organizer)
      ) {
        return {
          name: 'home-site',
          params: { personnelDivision: site[0] },
        }
      } else if (isAuthorized(actorRoles, siteClearance.responsible)) {
        return {
          name: 'home-site-responsible',
          params: { personnelDivision: site[0] },
        }
      } else if (
        isAuthorized(actorRoles, siteClearance.collaborator) ||
        isAuthorized(actorRoles, siteClearance.responsibleCollaborator)
      ) {
        return {
          name: 'home-site-collaborator',
          params: { personnelDivision: site[0] },
        }
      }
    },
    meta: {
      authRequired: true,
      authorize: [],
    },
  },
  // ///////////
  // HOME ROUTES
  // ///////////
  {
    path: '/map',
    name: 'spain-map',
    component: () => import('@views/home/spain-map.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.directorCoach,
        ...siteClearance.multicenterLeader,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/home/0',
    name: 'home-admin',
    component: () => import('@views/home/home-admin.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/es/:personnelDivision/home/1',
    name: 'home-site',
    component: () => import('@views/home/site/home-site.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.admin,
        ...siteClearance.organizer,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/home/2',
    name: 'home-site-responsible',
    component: () => import('@views/home/site/home-site-responsible.vue'),
    meta: {
      authRequired: true,
      authorize: siteClearance.responsible,
    },
  },
  {
    path: '/es/:personnelDivision/home/3',
    name: 'home-site-collaborator',
    component: () => import('@views/home/site/home-site-collaborator.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.collaborator,
        ...siteClearance.responsibleCollaborator,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/home/4',
    name: 'home-legal',
    component: () => import('@views/home/home-legal.vue'),
    meta: {
      authRequired: true,
      authorize: siteClearance.lawyer,
    },
  },
  /// ////////////////////////////
  // RECORD WORKDAY REPORTING
  /// ////////////////////////////
  {
    path: '/es/:personnelDivision/records-workday',
    name: 'records-workday',
    component: () => import('@/src/router/views/workday/records-workday.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.organizer,
        ...siteClearance.director,
        ...siteClearance.admin,
        ...siteClearance.responsible,
      ],
    },
  },
  // ///////////////////
  // RECORD WORKDAY MANAGEMENT
  // ///////////////////
  {
    path: '/recordWorkday-management',
    name: 'recordWorkday-management',
    component: () => import('@views/admin/workday/workday-management.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  // ///////////////////
  // ISSUES MANAGEMENT && ISSUES VIEW
  // ///////////////////
  {
    path: '/issues-management',
    name: 'issues-management',
    component: () => import('@views/admin/issues/issues-management.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/es/issues',
    name: 'issues',
    component: () => import('@views/issues/issues-page.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
        ...siteClearance.collaborator,
        ...siteClearance.responsibleCollaborator,
      ],
    },
  },
  // ///////////////////
  // COLLABORATOR ROUTES
  // ///////////////////
  {
    path: '/collaborators',
    component: () => import('@views/empty-router-view.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin, ...siteClearance.lawyer],
    },
    children: [
      {
        path: '',
        name: 'collaborator-list',
        component: () =>
          import('@views/collaborator/collaborator-list/collaborator-list.vue'),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin, ...siteClearance.lawyer],
        },
      },
      {
        path: 'deletedCollaborators',
        name: 'collaborator-list-deleted',
        component: () =>
          import(
            '@views/collaborator/collaborator-list/collaborator-list-deleted.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin, ...siteClearance.lawyer],
        },
      },
      {
        path: 'extraction',
        name: 'collaborators-extraction',
        component: () =>
          import(
            '@/src/router/views/collaborator/extraction/extraction-hr.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
    ],
  },
  {
    path: '/es/:personnelDivision/collaborators',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'collaborator-list-site',
        component: () =>
          import(
            '@views/collaborator/collaborator-list/collaborator-list-site.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.lawyer,
          ],
        },
      },
      {
        path: 'deletedCollaborators',
        name: 'collaborator-list-deleted-site',
        component: () =>
          import(
            '@views/collaborator/collaborator-list/collaborator-list-deleted-site.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.lawyer,
          ],
        },
      },
      {
        path: 'extraction',
        name: 'collaborators-extraction-site',
        component: () =>
          import('@views/collaborator/extraction/extraction-site.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: 'data-center',
        name: 'collaborators-data-center',
        component: () =>
          import('@views/collaborator/data-center/data-center.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: ':collaboratorUuid',
        name: 'collaborator-site-info',
        component: () =>
          import('@views/collaborator/collaborator-info/collaborator-info.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.collaborator,
            ...siteClearance.lawyer,
            ...siteClearance.responsibleCollaborator,
          ],
        },
      },
      {
        path: 'voluntary-resignation',
        component: () => import('@views/empty-router-view.vue'),
        children: [
          {
            path: '',
            name: 'voluntary-resignation',
            component: () =>
              import(
                '@views/collaborator/voluntary-resignation/voluntary-resignation.vue'
              ),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.collaborator,
                ...siteClearance.responsibleCollaborator,
              ],
            },
          },
          {
            path: 'create',
            name: 'create-voluntary-resignation',
            component: () =>
              import(
                '@views/collaborator/voluntary-resignation/createVoluntaryResignation.vue'
              ),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.collaborator,
                ...siteClearance.responsibleCollaborator,
              ],
            },
          },
          {
            path: ':processUuid/document',
            name: 'document-voluntary-resignation',
            component: () =>
              import(
                '@views/collaborator/voluntary-resignation/documentVoluntaryResignation.vue'
              ),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.collaborator,
                ...siteClearance.responsibleCollaborator,
              ],
            },
          },
          {
            path: ':processUuid/validate/:edit?',
            name: 'validate-code-voluntary-resignation',
            component: () =>
              import(
                '@views/collaborator/voluntary-resignation/validationCodeVoluntaryResignation.vue'
              ),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.collaborator,
                ...siteClearance.responsibleCollaborator,
              ],
            },
          },
        ],
      },
    ],
  },
  {
    path: '/es/:collaboratorUuid/selectRehireable/:processUuid',
    name: 'select-rehireable',
    component: () =>
      import('@views/leave/select-rehireable/select-rehireable.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
        ...siteClearance.lawyer,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/collaborator/:collaboratorUuid/document/contract/:contractStartDate',
    name: 'site-collaborator-contract-basic-document',
    component: () =>
      import('@views/contract/document/contract-basic-document.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
        ...siteClearance.collaborator,
        ...siteClearance.responsibleCollaborator,
      ],
    },
  },
  {
    path: '/es/collaborator/:collaboratorUuid/document/endOfContract',
    name: 'collaborator-end-of-contract-document',
    component: () =>
      import('@views/contract/document/end-of-contract-document.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/collaborator/:collaboratorUuid/document/endOfContract',
    name: 'site-collaborator-end-of-contract-document',
    component: () =>
      import('@views/contract/document/end-of-contract-document.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/es/collaborator/:collaboratorUuid/document/notPassedTrial',
    name: 'collaborator-not-passed-trial-document',
    component: () =>
      import('@views/contract/document/not-passed-trial-document.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/collaborator/:collaboratorUuid/document/notPassedTrial',
    name: 'site-collaborator-not-passed-trial-document',
    component: () =>
      import('@views/contract/document/not-passed-trial-document.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/es/collaborator/:collaboratorUuid/document/leaveOfAbsence',
    name: 'collaborator-leave-of-absence-document',
    component: () =>
      import('@views/leave/document/leave-of-absence-document.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/collaborator/:collaboratorUuid/document/leaveOfAbsence',
    name: 'site-collaborator-leave-of-absence-document',
    component: () =>
      import('@views/leave/document/leave-of-absence-document.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  // /////////////////
  // DIRECTOR COACHES
  // /////////////////
  {
    path: '/directorCoaches',
    component: () => import('@views/empty-router-view.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
    children: [
      {
        path: '',
        name: 'director-coaches-list',
        component: () =>
          import('@views/admin/director-coach/director-coaches-list.vue'),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: ':collaboratorUuid/sites',
        name: 'director-coach-sites',
        component: () =>
          import('@views/admin/director-coach/director-coach-sites.vue'),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
    ],
  },
  // /////////////////
  // PARAMETERS ROUTES
  // /////////////////
  {
    path: '/parameters',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'parameter-list',
        component: () => import('@views/common/parameters/parameter-list.vue'),
        meta: {
          authRequired: true,
          authorize: siteClearance.admin,
        },
      },
      {
        path: 'new',
        name: 'parameter-new',
        component: () => import('@views/common/parameters/parameter-new.vue'),
        meta: {
          authRequired: true,
          authorize: siteClearance.admin,
        },
      },
      {
        path: ':id',
        name: 'parameter-edit',
        component: () => import('@views/common/parameters/parameter-edit.vue'),
        meta: {
          authRequired: true,
          authorize: siteClearance.admin,
        },
      },
    ],
  },
  // /////////////////////////////
  // GENERAL OPEN PROCESSES ROUTES
  // ////////////////////////////
  {
    path: '/general/openProcesses',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'common-contract-processes',
        component: () =>
          import('@views/common/open-processes/open-processes.vue'),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin, ...siteClearance.lawyer],
        },
      },
      {
        path: 'extensions/:processUuid/edit',
        name: 'edit-extension-process',
        component: () =>
          import('@views/extension/edit-extension/edit-extension.vue'),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: 'conversions/:processUuid/edit',
        name: 'edit-conversion-process',
        component: () => import('@views/conversion/edit-conversion.vue'),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: 'conversionsCdiDf/:processUuid/edit',
        name: 'edit-conversion-cdi-df-process',
        component: () => import('@views/conversion/edit-conversion-cdidf.vue'),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: ':processUuid',
        component: () => import('@views/empty-router-view.vue'),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin, ...siteClearance.lawyer],
        },
        children: [
          {
            path: '',
            name: 'open-process-details',
            component: () =>
              import('@views/common/open-processes/open-process-details.vue'),
            meta: {
              authRequired: true,
              authorize: [...siteClearance.admin, ...siteClearance.lawyer],
            },
          },
          {
            path: 'rectify',
            name: 'edit-hiring',
            component: () =>
              import('@views/hiring/edit-hiring/edit-hiring.vue'),
            meta: {
              authRequired: true,
              authorize: [],
            },
          },
          {
            path: 'edit',
            name: 'full-edit-hiring',
            component: () =>
              import('@views/hiring/edit-hiring/full-edit-hiring.vue'),
            meta: {
              authRequired: true,
              authorize: [],
            },
          },
          {
            path: 'assignSap',
            name: 'assign-sap',
            component: () => import('@views/hiring/sap/assign-sap.vue'),
            meta: {
              authRequired: true,
              authorize: siteClearance.admin,
            },
          },
        ],
      },
    ],
  },
  // //////////////////////////////////
  // SITE GENERAL OPEN PROCESSES ROUTES
  // //////////////////////////////////
  {
    path: '/es/:personnelDivision/general/openProcesses',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'open-processes-site',
        component: () =>
          import('@views/common/open-processes/open-processes-site.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.lawyer,
          ],
        },
      },
      {
        path: 'extensions/:processUuid/edit',
        name: 'edit-extension-process-site',
        component: () =>
          import('@views/extension/edit-extension/edit-extension-site.vue'),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: 'conversions/:processUuid/edit',
        name: 'edit-conversion-process-site',
        component: () => import('@views/conversion/edit-conversion-site.vue'),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: 'conversionsCdiDf/:processUuid/edit',
        name: 'edit-conversion-cdi-df-process-site',
        component: () =>
          import('@views/conversion/edit-conversion-cdidf-site.vue'),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: 'conversionsCdi90Days/:processUuid/edit',
        name: 'edit-conversion-cdi-weekend-processes',
        component: () =>
          import('@views/conversion/edit-conversion-cdi90days.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: 'conversionsCdi90Days/:processUuid/edit',
        name: 'conversion-cdi-weekend-sign-on-paper',
        component: () =>
          import('@views/conversion/edit-conversion-cdi90days.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: ':processUuid',
        component: () => import('@views/empty-router-view.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.lawyer,
          ],
        },
        children: [
          {
            path: '',
            name: 'site-open-process-details',
            component: () =>
              import(
                '@views/common/open-processes/open-process-details-site.vue'
              ),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.admin,
                ...siteClearance.director,
                ...siteClearance.organizer,
                ...siteClearance.responsible,
                ...siteClearance.lawyer,
              ],
            },
          },
          {
            path: 'assignSap',
            name: 'site-assign-sap',
            component: () => import('@views/hiring/sap/assign-sap.vue'),
            meta: {
              authRequired: true,
              authorize: siteClearance.admin,
            },
          },
          {
            path: 'rectify',
            name: 'site-edit-hiring',
            component: () =>
              import('@views/hiring/edit-hiring/edit-hiring-site.vue'),
            meta: {
              authRequired: true,
              authorize: [],
            },
          },
          {
            path: 'edit',
            name: 'site-full-edit-hiring',
            component: () =>
              import('@views/hiring/edit-hiring/full-edit-hiring-site.vue'),
            meta: {
              authRequired: true,
              authorize: [],
            },
          },
        ],
      },
    ],
  },
  // //////////////////
  // CALLING CDI FD
  // /////////////////
  {
    path: '/es/:personnelDivision/calling-cdifd',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'calling-cdifd',
        component: () =>
          import(
            '@/src/router/views/common/calling-to-cdifd/calling-to-cdifd.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: ':collaboratorUuid',
        name: 'configure-calling',
        component: () =>
          import(
            '@/src/router/views/common/calling-to-cdifd/configure-calling.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: 'open-processes',
        component: () => import('@views/empty-router-view.vue'),
        children: [
          {
            path: '',
            name: 'open-cdifd-calling-processes',
            component: () =>
              import(
                '@/src/router/views/common/open-cdifd-calling-processes/open-cdifd-calling-processes.vue'
              ),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.admin,
                ...siteClearance.director,
                ...siteClearance.organizer,
                ...siteClearance.responsible,
              ],
            },
          },
          {
            path: ':collaboratorUuid/:processUuid',
            name: 'edit-cdifd-calling-processes',
            component: () =>
              import(
                '@/src/router/views/common/calling-to-cdifd/configure-calling.vue'
              ),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.admin,
                ...siteClearance.director,
                ...siteClearance.organizer,
                ...siteClearance.responsible,
              ],
            },
          },
        ],
      },
    ],
  },
  // //////////////////
  // CONVERSION CDI WEEKEND 90 DAYS
  // /////////////////
  {
    path: '/es/:personnelDivision/conversion-cdi-weekend',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: 'open-processes',
        component: () => import('@views/empty-router-view.vue'),
        children: [
          {
            path: '',
            name: 'open-conversion-cdi-weekend-processes',
            component: () =>
              import(
                '@/src/router/views/common/open-conversion-cdi-weekend-processes/open-conversion-cdi-weekend-processes.vue'
              ),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.admin,
                ...siteClearance.director,
                ...siteClearance.organizer,
                ...siteClearance.responsible,
              ],
            },
          },
        ],
      },
    ],
  },
  // /////////////
  // HIRING ADMIN ROUTES
  // /////////////
  {
    path: '/hiring/pendingSapHiringProcesses',
    name: 'pending-sap-hiring-processes',
    component: () =>
      import('@views/hiring/sap/pending-sap-hiring-processes.vue'),
    meta: {
      authRequired: true,
      authorize: siteClearance.admin,
    },
  },
  {
    path: '/hiring/multipleSapAssignation',
    name: 'multiple-sap-assignation',
    component: () => import('@views/hiring/sap/multiple-sap-assignation.vue'),
    meta: {
      authRequired: true,
      authorize: siteClearance.admin,
    },
  },
  // ///////////////////
  // SITE HIRING ROUTES
  // //////////////////
  {
    path: '/es/:personnelDivision/hiring',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'site-id-check',
        component: () => import('@views/hiring/id-check/id-check.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: 'stepper',
        name: 'hiring-stepper',
        component: () =>
          import('@views/hiring/new-hiring-stepper/new-hiring-stepper.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: 'newHiring',
        name: 'site-new-hiring',
        component: () => import('@views/hiring/new-hiring/new-hiring.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: 'rehiring',
        component: () => import('@views/empty-router-view.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
        children: [
          {
            path: 'rehireInfo/:collaboratorUuid',
            name: 'rehire-info-site',
            component: () =>
              import('@views/hiring/re-hiring/rehire-info/rehire-info.vue'),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.admin,
                ...siteClearance.director,
                ...siteClearance.organizer,
                ...siteClearance.responsible,
              ],
            },
          },
          {
            path: 'rehire/:collaboratorUuid',
            name: 'rehire-site',
            component: () => import('@views/hiring/re-hiring/re-hiring.vue'),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.admin,
                ...siteClearance.director,
                ...siteClearance.organizer,
                ...siteClearance.responsible,
              ],
            },
          },
        ],
      },
    ],
  },
  // ////////////////////////////
  // FICTITIOUS ROUTES
  // ////////////////////////////
  // ////////////////////////////
  // HIRE FICTITIOUS
  // ////////////////////////////
  {
    path: '/es/:personnelDivision/hireFictitious',
    name: 'hire-fictitious',
    component: () =>
      import('@router/views/hiring/hire-fictitious/hire-fictitious.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  // ////////////////////////////
  // FICTITIOUS LIST
  // ////////////////////////////
  {
    path: '/es/:personnelDivision/fictitious',
    name: 'fictitious-list',
    component: () =>
      import('@router/views/collaborator/fictitious/fictitious-list.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  // ////////////////////////////
  // UPDATE FICTITIOUS
  // ////////////////////////////
  {
    path: '/es/:personnelDivision/fictitious/:fictitiousUuid',
    name: 'fictitious-update',
    component: () =>
      import('@router/views/collaborator/fictitious/fictitious-update.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  /// ///////////////////////////
  // SITE REACTIVATION ROUTES
  /// //////////////////////////
  {
    path: '/es/:personnelDivision/reactivate/:collaboratorUuid',
    component: () => import('@views/empty-router-view.vue'),
    meta: {
      authRequired: true,
      authorize: [],
      ...siteClearance.admin,
    },
    children: [
      {
        path: '',
        name: 'reactivate-collaborator-site',
        component: () =>
          import('@views/collaborator/reactivation/reactivation-site.vue'),
        meta: {
          authRequired: true,
          authorize: [],
          ...siteClearance.admin,
        },
      },
      {
        path: 'undoMistakenLeaveAndEditContractEndDate',
        name: 'undo-mistaken-leave-and-edit-contract-end-date',

        component: () =>
          import(
            '@views/collaborator/undo-leave-and-edit-contract-end-date/undo-leave-and-edit-contract-end-date-site.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [],
          ...siteClearance.admin,
        },
      },
    ],
  },

  // ////////////////////////////
  // HIRING COLLABORATOR'S ROUTES
  // ////////////////////////////
  {
    path: '/idTokenCheck/:token',
    name: 'id-token-check',
    component: () => import('@views/hiring/id-token-check/id-token-check.vue'),
    meta: {
      authRequired: false,
      authorize: [],
    },
  },
  {
    path: '/insertCollaboratorData',
    name: 'insert-collaborator-data',
    component: () =>
      import(
        '@views/hiring/insert-collaborator-data/insert-collaborator-data.vue'
      ),
    beforeEnter: (to, from, next) => {
      if (
        store.state.contractManager.verificationDocumentTokenData.token === ''
      ) {
        next({ path: '/' }) // decide view to redirect when user access directly without verification
      } else {
        next()
      }
    },
    meta: {
      authRequired: false,
      authorize: [],
    },
  },
  // /////////////
  // LEAVE ROUTES
  // /////////////
  {
    path: '/leave',
    component: () => import('@views/empty-router-view.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
    children: [
      {
        path: 'openLeaveProcesses',
        component: () => import('@views/empty-router-view.vue'),
        children: [
          {
            path: '',
            name: 'open-leave-processes',
            component: () =>
              import('@views/leave/open-process/open-leave-processes.vue'),
            meta: {
              authRequired: true,
              authorize: [...siteClearance.admin],
            },
          },
          {
            path: ':processUuid',
            name: 'open-leave-process-details',
            component: () =>
              import(
                '@views/leave/leave-process-details/leave-process-details.vue'
              ),
            meta: {
              authRequired: true,
              authorize: [...siteClearance.admin],
            },
          },
        ],
      },
      {
        path: 'pendingSapLeaveProcesses',
        name: 'pending-sap-leave-processes',
        component: () =>
          import('@views/leave/sap/pending-sap-leave-processes.vue'),
        meta: {
          authRequired: true,
          authorize: siteClearance.admin,
        },
      },
      {
        path: 'openLeaveOfAbsenceProcesses',
        component: () => import('@views/empty-router-view.vue'),
        children: [
          {
            path: '',
            name: 'open-leave-of-absence-processes',
            component: () =>
              import(
                '@views/leave/open-process/open-leave-of-absence-processes.vue'
              ),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.admin,
                ...siteClearance.director,
                ...siteClearance.organizer,
                ...siteClearance.responsible,
                ...siteClearance.lawyer,
              ],
            },
          },
          {
            path: ':processUuid',
            name: 'site-open-leave-of-absence-process-details',
            component: () =>
              import(
                '@views/leave/leave-process-details/leave-process-details.vue'
              ),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.admin,
                ...siteClearance.director,
                ...siteClearance.organizer,
                ...siteClearance.responsible,
                ...siteClearance.lawyer,
              ],
            },
          },
        ],
      },
    ],
  },
  {
    path: '/pendingSapLeaveOfAbsenceExtensionProcesses',
    name: 'pending-sap-leave-of-absence-extension-processes',
    component: () =>
      import(
        '@views/leave/sap/pending-sap-leave-of-absence-extension-processes.vue'
      ),
    meta: {
      authRequired: true,
      authorize: siteClearance.admin,
    },
  },
  // //////////////////
  // SITE LEAVE ROUTES
  // /////////////////
  {
    path: '/es/:personnelDivision/leave',
    component: () => import('@views/empty-router-view.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
    children: [
      {
        path: 'openLeaveProcesses',
        component: () => import('@views/empty-router-view.vue'),
        children: [
          {
            path: '',
            name: 'site-open-leave-processes',
            component: () =>
              import('@views/leave/open-process/site-open-leave-processes.vue'),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.admin,
                ...siteClearance.director,
                ...siteClearance.organizer,
                ...siteClearance.responsible,
                ...siteClearance.lawyer,
              ],
            },
          },
          {
            path: ':processUuid',
            name: 'site-open-leave-process-details',
            component: () =>
              import(
                '@views/leave/site-leave-process-details/site-leave-process-details.vue'
              ),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.admin,
                ...siteClearance.director,
                ...siteClearance.organizer,
                ...siteClearance.responsible,
                ...siteClearance.lawyer,
              ],
            },
          },
        ],
      },
      {
        path: 'openLeaveOfAbsenceProcesses',
        component: () => import('@views/empty-router-view.vue'),
        children: [
          {
            path: '',
            name: 'site-open-leave-of-absence-processes',
            component: () =>
              import(
                '@views/leave/open-process/site-open-leave-of-absence-processes.vue'
              ),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.admin,
                ...siteClearance.director,
                ...siteClearance.organizer,
                ...siteClearance.responsible,
                ...siteClearance.lawyer,
              ],
            },
          },
          {
            path: ':processUuid',
            name: 'open-leave-of-absence-process-details',
            component: () =>
              import(
                '@views/leave/leave-process-details/leave-process-details.vue'
              ),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.admin,
                ...siteClearance.director,
                ...siteClearance.organizer,
                ...siteClearance.responsible,
                ...siteClearance.lawyer,
              ],
            },
          },
        ],
      },
      {
        path: ':collaboratorUuid',
        name: 'site-leave',
        component: () =>
          import('@/src/router/views/leave/leave-form/leave-form.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
    ],
  },
  // //////////////////
  // TEAM CHANGE PROCESSES
  // /////////////////
  {
    path: '/es/:personnelDivision/teamChangeProcesses',
    name: 'team-change-processes',
    component: () =>
      import(
        '@/src/router/views/common/team-change-processes/team-change-processes.vue'
      ),

    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  // //////////////////
  // POSITION CHANGE PROCESSES
  // /////////////////
  {
    path: '/es/:personnelDivision/positionChangeProcesses',
    name: 'position-change-processes',
    component: () =>
      import(
        '@/src/router/views/common/position-change-processes/position-change-processes.vue'
      ),

    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  // //////////////////
  // CONVERSION ROUTES
  // /////////////////
  {
    path: '/es/:personnelDivision/conversion/:collaboratorUuid',
    name: 'collaborator-conversion',
    component: () =>
      import('@/src/router/views/conversion/conversion-form.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/conversion-with-reactivation/:collaboratorUuid',
    name: 'collaborator-conversion-with-reactivation',
    component: () =>
      import(
        '@/src/router/views/conversion/conversion-form-with-reactivation.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/es/:personnelDivision/conversion-cdifd/:collaboratorUuid',
    name: 'collaborator-conversion-cdifd',
    component: () =>
      import('@/src/router/views/conversion/conversion-form-cdifd.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/conversion-cdi90days/:collaboratorUuid',
    name: 'collaborator-conversion-cdi90days',
    component: () =>
      import('@/src/router/views/conversion/conversion-form-cdi90days.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/conversion-cdifd-with-reactivation/:collaboratorUuid',
    name: 'collaborator-conversion-cdifd-with-reactivation',
    component: () =>
      import(
        '@/src/router/views/conversion/conversion-form-cdifd-with-reactivation.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/pendingSapConversionProcesses',
    name: 'pending-sap-conversion-processes',
    component: () =>
      import(
        '@/src/router/views/conversion/pending-sap-conversion-processes.vue'
      ),
    meta: {
      authRequired: true,
      authorize: siteClearance.admin,
    },
  },
  // //////////////////
  // EXTENSION ROUTES
  // /////////////////
  {
    path: '/es/:personnelDivision/extension/:collaboratorUuid',
    name: 'contract-extension',
    component: () => import('@views/contract/contract-extension.vue'),
    meta: {
      authRequired: true,
      authorize: [],
    },
  },
  {
    path: '/es/:personnelDivision/extension-with-reactivation/:collaboratorUuid',
    name: 'contract-extension-with-reactivation',
    component: () =>
      import('@views/contract/contract-extension-with-reactivation.vue'),
    meta: {
      authRequired: true,
      authorize: [],
    },
  },
  {
    path: '/pendingSapContractExtensionProcesses',
    name: 'pending-sap-contract-extension-processes',
    component: () =>
      import('@views/contract/pending-sap-contract-extension-processes.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  // //////////////////
  // TEAM CHANGE
  // /////////////////
  {
    path: '/es/:personnelDivision/teamChange/:collaboratorUuid',
    name: 'collaborator-team-change',
    component: () => import('@views/collaborator/team-change/team-change.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/teamChangeEdition/:processUuid',
    name: 'team-change-processes-edition',
    component: () =>
      import('@views/collaborator/team-change/edit-team-change.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  // //////////////////
  // POSITION CHANGE
  // /////////////////
  {
    path: '/es/:personnelDivision/positionChange/:collaboratorUuid',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'create-collaborator-position-change',
        component: () =>
          import(
            '@views/collaborator/position-change/create-position-change.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: ':positionChangeProcessUuid',
        name: 'edit-collaborator-position-change',
        component: () =>
          import(
            '@views/collaborator/position-change/edit-position-change.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
    ],
  },
  // //////////////////
  // MUTATION ROUTES
  // /////////////////
  {
    path: '/es/:personnelDivision/mutation/:collaboratorUuid',
    name: 'collaborator-mutation',
    component: () =>
      import(
        '@views/collaborator/collaborator-mutation/collaborator-mutation.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin, ...siteClearance.director],
    },
  },
  {
    path: '/es/mutations',
    name: 'mutations-list',
    component: () =>
      import(
        '@views/collaborator/collaborator-mutation/collaborator-mutation-list.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/es/:personnelDivision/mutations',
    name: 'site-mutations-list',
    component: () =>
      import(
        '@views/collaborator/collaborator-mutation/collaborator-mutation-site-list.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },

  // //////////////////
  // TIMEBASE REPORT SITE
  // /////////////////
  {
    path: '/es/:personnelDivision/timebaseReport',
    name: 'timebase-report-site',
    component: () =>
      import('@views/common/timebase-report-site/timebase-report-site.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  /// ///////////////////////
  // TEAM COHERENCE REPORT
  /// //////////////////////
  {
    path: '/es/:personnelDivision/team-coherence-report',
    name: 'team-coherence-report',
    component: () =>
      import(
        '@views/collaborator/team-coherence-report/team-coherence-report.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },

  // //////////////////
  // EFFECTIVE REST REPORT SITE
  // /////////////////
  {
    path: '/es/:personnelDivision/effectiveRestReportByTeam',
    name: 'effective-rest-report-team',
    component: () =>
      import(
        '@views/common/effective-rest-report-team/effective-rest-report-team.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/effectiveRestReportByCollaborator/:collaboratorUuid/:year',
    name: 'effective-rest-report-collaborator',
    component: () =>
      import(
        '@views/common/effective-rest-report-collaborator/effective-rest-report-collaborator.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },

  // //////////////////
  // EFFECTIVE REST REPORT SITE
  // /////////////////
  {
    path: '/es/:personnelDivision/effectiveRestReportByTeam',
    name: 'effective-rest-report-team',
    component: () =>
      import(
        '@views/common/effective-rest-report-team/effective-rest-report-team.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/effectiveRestReportByCollaborator/:collaboratorUuid/:year',
    name: 'effective-rest-report-collaborator',
    component: () =>
      import(
        '@views/common/effective-rest-report-collaborator/effective-rest-report-collaborator.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },

  // //////////////////
  // EFFECTIVE REST REPORT SITE
  // /////////////////
  {
    path: '/es/:personnelDivision/effectiveRestReportByTeam',
    name: 'effective-rest-report-team',
    component: () =>
      import(
        '@views/common/effective-rest-report-team/effective-rest-report-team.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/effectiveRestReportByCollaborator/:collaboratorUuid/:year',
    name: 'effective-rest-report-collaborator',
    component: () =>
      import(
        '@views/common/effective-rest-report-collaborator/effective-rest-report-collaborator.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },

  // //////////////////
  // EFFECTIVE REST REPORT SITE
  // /////////////////
  {
    path: '/es/:personnelDivision/effectiveRestReportByTeam',
    name: 'effective-rest-report-team',
    component: () =>
      import(
        '@views/common/effective-rest-report-team/effective-rest-report-team.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/effectiveRestReportByCollaborator/:collaboratorUuid/:year',
    name: 'effective-rest-report-collaborator',
    component: () =>
      import(
        '@views/common/effective-rest-report-collaborator/effective-rest-report-collaborator.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },

  // //////////////////
  // LABOR CONDITION ROUTES
  // /////////////////

  // //////////////////
  // HOUR CHANGES ROUTES
  // /////////////////
  {
    path: '/es/:personnelDivision/timebaseChange/:collaboratorUuid',
    name: 'collaborator-hour-change',
    component: () =>
      import(
        '@router/views/laborCondition/hour-change/hour-change-process.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/end-shift-reduction/:collaboratorUuid',
    name: 'end-shift-reduction',
    component: () =>
      import(
        '@router/views/laborCondition/end-shift-reduction/end-shift-reduction.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/editShiftReductionProcess/:collaboratorUuid/:processUuid',
    name: 'edit-shift-reduction-process',
    component: () =>
      import(
        '@router/views/laborCondition/edit-shift-reduction/edit-shift-reduction-process.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/es/:personnelDivision/editShiftReductionProcess/:collaboratorUuid/:processUuid',
    name: 'edit-shift-reduction-process-site',
    component: () =>
      import(
        '@router/views/laborCondition/edit-shift-reduction/edit-shift-reduction-process-site.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/timebaseChangeProcesses',
    name: 'timebase-change-processes-list',
    component: () =>
      import(
        '@router/views/laborCondition/hour-change/timebase-change-processes-list.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/es/:personnelDivision/timebaseChangeProcesses',
    name: 'timebase-change-processes-list-site',
    component: () =>
      import(
        '@router/views/laborCondition/hour-change/timebase-change-processes-list-site.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/editTimebaseChangeProcess/:collaboratorUuid/:processUuid',
    name: 'timebase-change-processes-edit',
    component: () =>
      import(
        '@router/views/laborCondition/edit-timebase/edit-timebase-process.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/es/:personnelDivision/editTimebaseChangeProcess/:collaboratorUuid/:processUuid',
    name: 'timebase-change-processes-edit-site',
    component: () =>
      import(
        '@router/views/laborCondition/edit-timebase/edit-timebase-process-site.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/timebaseChangeProcesses/:processUuid/document',
    name: 'timebase-change-process-document',
    component: () =>
      import(
        '@router/views/laborCondition/document/timebase-change-document-view.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
        ...siteClearance.collaborator,
        ...siteClearance.responsibleCollaborator,
      ],
    },
  },
  {
    path: '/pendingSapHourChangeProcesses',
    name: 'pending-sap-hour-change-processes',
    component: () =>
      import(
        '@views/laborCondition/hour-change/pending-sap-hour-change-processes.vue'
      ),
    meta: {
      authRequired: true,
      authorize: siteClearance.admin,
    },
  },
  // //////////////////
  // COMPLEMENTARY PACT ROUTES
  // /////////////////
  {
    path: '/es/:personnelDivision/complementaryPact/:collaboratorUuid',
    name: 'complementary-pact',
    component: () =>
      import(
        '@router/views/laborCondition/complementary-pact/complementary-pact.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/es/complementaryPactProcesses',
    name: 'complementary-pact-list',
    component: () =>
      import(
        '@router/views/laborCondition/complementary-pact/complementary-pact-list.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/es/:personnelDivision/complementaryPactProcesses',
    name: 'complementary-pact-list-site',
    component: () =>
      import(
        '@router/views/laborCondition/complementary-pact/complementary-pact-list-site.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/complementaryPactProcesses/:processUuid/document',
    name: 'complementary-pact-process-document-site',
    component: () =>
      import(
        '@router/views/laborCondition/document/complementary-pact-document-view.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
        ...siteClearance.collaborator,
        ...siteClearance.responsibleCollaborator,
      ],
    },
  },
  {
    path: '/es/complementaryPactProcesses/:processUuid/document',
    name: 'complementary-pact-process-document',
    component: () =>
      import(
        '@router/views/laborCondition/document/complementary-pact-document-view.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
        ...siteClearance.collaborator,
        ...siteClearance.responsibleCollaborator,
      ],
    },
  },
  {
    path: '/editComplementaryPactProcess/:collaboratorUuid/:processUuid',
    name: 'edit-complementary-pact-process',
    component: () =>
      import(
        '@router/views/laborCondition/edit-complementary-pact/edit-complementary-pact-process.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/es/:personnelDivision/editComplementaryPactProcess/:collaboratorUuid/:processUuid',
    name: 'edit-complementary-pact-process-site',
    component: () =>
      import(
        '@router/views/laborCondition/edit-complementary-pact/edit-complementary-pact-process-site.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  // ////////////////////////
  // TEAM OR POSITION CHANGES ROUTES
  // ////////////////////////
  {
    path: '/teamOrPositionChanges',
    name: 'team-or-position-changes',
    component: () =>
      import('@views/laborCondition/team-or-position-changes.vue'),
    meta: {
      authRequired: true,
      authorize: siteClearance.admin,
    },
  },

  // ////////////////////////
  // SIGNATURE RESPONSE STATUS ROUTES
  // ////////////////////////
  {
    path: '/signatureReponseStatuses',
    name: 'signature-response-statuses',
    component: () =>
      import(
        '@views/admin/signature-response-status/signature-response-status.vue'
      ),
    meta: {
      authRequired: true,
      authorize: siteClearance.admin,
    },
  },

  // ////////////////////////
  // SCHEDULED PROCESSES ROUTES
  // ////////////////////////
  {
    path: '/scheduledProcesses',
    name: 'scheduled-processes',
    component: () =>
      import('@views/admin/scheduled-processes/scheduled-processes.vue'),
    meta: {
      authRequired: true,
      authorize: siteClearance.admin,
    },
  },

  // ////////////////////////
  // LEAVE OF ABSENCE ROUTES
  // ////////////////////////
  {
    path: '/es/leaveOfAbsenceExtension/:processUuid',
    name: 'leave-of-absence-extension',
    component: () =>
      import(
        '@views/leave/leave-of-absence-extension/leave-of-absence-extension.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/es/leaveOfAbsenceExtensionDetails/:processUuid',
    name: 'leave-of-absence-extension-details',
    component: () =>
      import(
        '@views/leave/leave-of-absence-extension-details/leave-of-absence-extension-details.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin, ...siteClearance.lawyer],
    },
  },
  {
    path: '/es/:personnelDivision/rehiringsFromLeaveOfAbsence/:processUuid/rectify',
    name: 'rectify-rehiring-from-leave-of-absence-site',
    component: () =>
      import(
        '@views/hiring/rectify-rehiring-from-leave-of-absence/rectify-rehiring-from-leave-of-absence.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
        ...siteClearance.lawyer,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/leaveOfAbsenceExtension/:processUuid',
    name: 'leave-of-absence-extension-site',
    component: () =>
      import(
        '@views/leave/site-leave-of-absence-extension/site-leave-of-absence-extension.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
        ...siteClearance.lawyer,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/leaveOfAbsenceExtensionDetails/:processUuid',
    name: 'site-leave-of-absence-extension-details',
    component: () =>
      import(
        '@views/leave/site-leave-of-absence-extension-details/site-leave-of-absence-extension-details.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
        ...siteClearance.lawyer,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/leaveOfAbsenceReEntry/:processUuid',
    name: 'leave-of-absence-re-entry-site',
    component: () =>
      import(
        '@views/hiring/leave-of-absence-re-entry/leave-of-absence-re-entry.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
        ...siteClearance.lawyer,
      ],
    },
  },
  /// ///////////////////////
  // SALARY ADVANCE ROUTES
  /// //////////////////////
  {
    path: '/es/:personnelDivision/salaryAdvance',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'collaborator-salary-advance-list',
        component: () =>
          import(
            '@views/salary-advance/salary-advance-list/salary-advance-list.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.responsible,
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.collaborator,
            ...siteClearance.lawyer,
            ...siteClearance.responsibleCollaborator,
          ],
        },
      },
      {
        path: 'create',
        name: 'salary-advance-create',
        component: () =>
          import(
            '@views/salary-advance/salary-advance-create/salary-advance-create.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.responsible,
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.collaborator,
            ...siteClearance.responsibleCollaborator,
            ...siteClearance.lawyer,
          ],
        },
      },
      {
        path: ':salaryAdvanceUuid',
        name: 'salary-advance-update',
        component: () =>
          import(
            '@views/salary-advance/salary-advance-update/salary-advance-update.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.responsible,
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.collaborator,
            ...siteClearance.responsibleCollaborator,
            ...siteClearance.lawyer,
          ],
        },
      },
    ],
  },

  /// /////////////////////////////////
  // SALARY ADVANCE MANAGEMENT ROUTES
  /// ////////////////////////////////
  {
    path: '/salaryAdvanceManagement',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'salary-advance-management',
        component: () =>
          import(
            '@/src/router/views/salary-advance/salary-advance-management/salary-advance-management.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
    ],
  },

  /// /////////////////////////
  // TEAMS MANAGEMENT
  /// /////////////////////////
  {
    path: '/teamsManagement',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'teams-management-list',
        component: () =>
          import(
            '@/src/router/views/admin/teams-management/teams-management-list/teams-management-list.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: 'create',
        name: 'teams-management-create-team',
        component: () =>
          import(
            '@/src/router/views/admin/teams-management/teams-management-create-team/teams-management-create-team.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: ':teamUuid',
        name: 'teams-management-update-team',
        component: () =>
          import(
            '@/src/router/views/admin/teams-management/teams-management-update-team/teams-management-update-team.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
    ],
  },

  /// /////////////////////////
  // SITE MANAGEMENT
  /// /////////////////////////
  {
    path: '/sitesManagement',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'sites-management-site-list',
        component: () =>
          import(
            '@/src/router/views/admin/sites-management/sites-management-site-list/sites-management-site-list.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: 'create',
        name: 'sites-management-create-site',
        component: () =>
          import(
            '@/src/router/views/admin/sites-management/sites-management-create-site/sites-management-create-site.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: 'update/:personnelDivision',
        name: 'sites-management-update-site',
        component: () =>
          import(
            '@/src/router/views/admin/sites-management/sites-management-update-site/sites-management-update-site.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: 'siteManagers/:personnelDivision',
        name: 'sites-management-site-managers',
        component: () =>
          import(
            '@/src/router/views/admin/sites-management/sites-management-site-managers/sites-management-site-managers.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
    ],
  },

  {
    path: '/hireableWindows',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'hireable-windows',
        component: () =>
          import(
            '@/src/router/views/hiring/hireable-windows/hireable-windows.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
    ],
  },

  // ////////////////////////
  // CDI FD WINDOWS
  // ////////////////////////
  {
    path: '/hireableWindows/cdiFdWindows',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: 'create',
        name: 'cdifd-windows-create',
        component: () =>
          import(
            '@views/hiring/hireable-windows/cdifd-windows/hireable-windows-create.vue'
          ),
        meta: {
          authRequired: true,
          authorize: siteClearance.admin,
        },
      },
      {
        path: ':uuid',
        component: () => import('@views/empty-router-view.vue'),

        children: [
          {
            path: '',
            name: 'cdifd-windows-update',
            component: () =>
              import(
                '@views/hiring/hireable-windows/cdifd-windows/hireable-windows-update.vue'
              ),
            meta: {
              authRequired: true,
              authorize: siteClearance.admin,
            },
          },
        ],
      },
    ],
  },

  // ////////////////////////
  // 90 DAYS WINDOWS
  // ////////////////////////
  {
    path: '/hireableWindows/windows90Days',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: 'create',
        name: 'windows-90-days-create',
        component: () =>
          import(
            '@views/hiring/hireable-windows/windows-90-days/windows-90-days-create.vue'
          ),
        meta: {
          authRequired: true,
          authorize: siteClearance.admin,
        },
      },
      {
        path: ':windowUuid',

        name: 'windows-90-days-update',
        component: () =>
          import(
            '@views/hiring/hireable-windows/windows-90-days/windows-90-days-update.vue'
          ),
        meta: {
          authRequired: true,
          authorize: siteClearance.admin,
        },

        children: [
          {
            path: 'commercialOperations',
            name: 'windows-90-days-commercial-operations',
            component: () =>
              import(
                '@views/hiring/commercial-operation/commercial-operation.vue'
              ),
            meta: {
              authRequired: true,
              authorize: siteClearance.admin,
            },
          },
          {
            path: 'commercialOperations/create',
            name: 'windows-90-days-commercial-operation-create',
            component: () =>
              import(
                '@views/hiring/commercial-operation/commercial-operation-create.vue'
              ),
            meta: {
              authRequired: true,
              authorize: siteClearance.admin,
            },
          },
          {
            path: 'commercialOperations/:uuid',
            name: 'windows-90-days-commercial-operation-update',
            component: () =>
              import(
                '@views/hiring/commercial-operation/commercial-operation-update.vue'
              ),
            meta: {
              authRequired: true,
              authorize: siteClearance.admin,
            },
          },
        ],
      },
    ],
  },

  // ////////////////////////
  // 90 DAYS WINDOWS
  // ////////////////////////
  {
    path: '/hireableWindows/windows90Days',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: 'create',
        name: 'windows-90-days-create',
        component: () =>
          import(
            '@views/hiring/hireable-windows/windows-90-days/windows-90-days-create.vue'
          ),
        meta: {
          authRequired: true,
          authorize: siteClearance.admin,
        },
      },
      {
        path: ':windowUuid',
        component: () => import('@views/empty-router-view.vue'),

        children: [
          {
            path: '',
            name: 'windows-90-days-update',
            component: () =>
              import(
                '@views/hiring/hireable-windows/windows-90-days/windows-90-days-update.vue'
              ),
            meta: {
              authRequired: true,
              authorize: siteClearance.admin,
            },
          },
          {
            path: 'commercialOperations',
            name: 'windows-90-days-commercial-operations',
            component: () =>
              import(
                '@views/hiring/commercial-operation/commercial-operation.vue'
              ),
            meta: {
              authRequired: true,
              authorize: siteClearance.admin,
            },
          },
          {
            path: 'commercialOperations/create',
            name: 'windows-90-days-commercial-operation-create',
            component: () =>
              import(
                '@views/hiring/commercial-operation/commercial-operation-create.vue'
              ),
            meta: {
              authRequired: true,
              authorize: siteClearance.admin,
            },
          },
          {
            path: 'commercialOperations/:uuid',
            name: 'windows-90-days-commercial-operation-update',
            component: () =>
              import(
                '@views/hiring/commercial-operation/commercial-operation-update.vue'
              ),
            meta: {
              authRequired: true,
              authorize: siteClearance.admin,
            },
          },
        ],
      },
    ],
  },

  // ////////////////////////
  // OTHER ROUTES
  // ////////////////////////

  // ////////////////////////
  // PENDING SAP IBAN CHANGE PROCESSES
  // ////////////////////////
  {
    path: '/pendingSapIbanChangeProcesses',
    name: 'pending-sap-iban-change-processes',
    component: () =>
      import(
        '@views/collaborator/collaborator-iban-change/pending-sap-iban-change-processes.vue'
      ),
    meta: {
      authRequired: true,
      authorize: siteClearance.admin,
    },
  },
  // ////////////////////////
  // PENDING SAP ADDRESS CHANGE PROCESSES
  // ////////////////////////
  {
    path: '/pendingSapAddressChangeProcesses',
    name: 'pending-sap-address-change-processes',
    component: () =>
      import(
        '@views/collaborator/collaborator-address-change/pending-sap-address-change-processes.vue'
      ),
    meta: {
      authRequired: true,
      authorize: siteClearance.admin,
    },
  },
  // ////////////////////////
  // FEATURE TOGGLE
  // ////////////////////////
  {
    path: '/featureToggle',
    name: 'feature-toggle',
    component: () => import('@views/admin/feature-toggle/feature-toggle.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },

  // ///////////
  // AUTH ROUTES
  // ///////////
  {
    path: '/login',
    name: 'login',
    component: () => import('@/src/router/views/auth/login-view.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ path: '/' })
        } else {
          store
            .dispatch('auth/sendToken', { code: routeTo.query.code })
            .then((resp) => {
              next()
            })
            .catch((error) => console.warn(error))
        }
      },
    },
  },
  {
    path: '/loginFedId',
    name: 'loginFedId',
    component: () => import('@/src/router/views/auth/login-fed-id.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ path: '/' })
        } else {
          store
            .dispatch('auth/getFedIdUrl')
            .then((response) => {
              window.location = response.data.url
            })
            .catch((error) => console.warn(error))
          next()
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/src/router/views/auth/logout-view.vue'),
    meta: {
      authRequired: true,
      authorize: [],
      beforeResolve(routeTo, routeFrom, next) {
        store
          .dispatch('auth/logOut')
          .then(() => {
            next()
          })
          .catch((error) => console.warn(error))
      },
    },
  },
  {
    path: '/welcomeNewCollaborator',
    name: 'welcomeNewCollaborator',
    component: () => import('@views/hiring/welcome-new-collaborator.vue'),
    meta: {
      authRequired: false,
      authorize: [],
    },
  },
  {
    path: '/identityDataDoNotMatchEplanning',
    name: 'identityDataDoNotMatchEplanning',
    component: () =>
      import('@views/identityAccess/identity-access-data-matching-error.vue'),
    meta: {
      authRequired: false,
      authorize: [],
    },
  },
  {
    path: '/absenteeismManagement',
    name: 'absenteeismManagement',
    component: () =>
      import(
        '@views/collaborator/absenteeism-management/absenteeism-management.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/404',
    name: '404',
    component: require('@views/_404.vue').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '/:catchAll(.*)*',
    redirect: '404',
  },
  // /////////////////
  // FORCE DATA UPDATE ROUTES
  // /////////////////
  {
    path: '/force-data-update',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'force-data-update-list',
        component: () =>
          import('@views/common/force-data-update/force-data-update-list.vue'),
        meta: {
          authRequired: true,
          authorize: siteClearance.admin,
        },
      },
      {
        path: 'create',
        name: 'force-data-update-create',
        component: () =>
          import(
            '@views/common/force-data-update/force-data-update-create.vue'
          ),
        meta: {
          authRequired: true,
          authorize: siteClearance.admin,
        },
      },
      {
        path: ':uuid',
        component: () => import('@views/empty-router-view.vue'),
        children: [
          {
            path: '',
            name: 'force-data-update-update',
            component: () =>
              import(
                '@views/common/force-data-update/force-data-update-update.vue'
              ),
            meta: {
              authRequired: true,
              authorize: siteClearance.admin,
            },
          },
        ],
      },
    ],
  },
]
