export const availableTransitions = {
  CANCELLABLE_STATUSES: [
    'WAITING_FOR_COLLABORATOR_DATA',
    'WAITING_FOR_DIRECTOR_VALIDATION',
    'WAITING_FOR_DIRECTOR_AND_LEGAL_VALIDATION',
    'WAITING_FOR_HR_DATA',
    'WAITING_FOR_LEGAL_VALIDATION',
    'QUEUED_SEND_EMAIL_PENDING',
    'WAITING_TO_SEND_CONTRACT',
    'WAITING_TO_SIGN_CONTRACT',
  ],
  DIRECTOR_VALIDATION_STATUSES: [
    'WAITING_FOR_DIRECTOR_VALIDATION',
    'WAITING_FOR_DIRECTOR_AND_LEGAL_VALIDATION',
  ],
  LEGAL_VALIDATION_STATUSES: [
    'WAITING_FOR_LEGAL_VALIDATION',
    'WAITING_FOR_DIRECTOR_AND_LEGAL_VALIDATION',
  ],
  RECTIFY_CONTRACT_DATA_STATUSES: [
    'WAITING_FOR_DIRECTOR_VALIDATION',
    'WAITING_FOR_DIRECTOR_AND_LEGAL_VALIDATION',
    'WAITING_FOR_LEGAL_VALIDATION',
    'QUEUED_SEND_EMAIL_PENDING',
    'WAITING_FOR_COLLABORATOR_DATA',
  ],
  MODIFY_CONTRACT_DATA_STATUSES: [
    'WAITING_FOR_HR_DATA',
    'WAITING_TO_SEND_CONTRACT',
    'WAITING_TO_SIGN_CONTRACT',
  ],
  MODIFY_CALL_DISCONTINUOUS_FIXED_DATA_STATUSES: ['SENT', 'INITIATED'],
  CANCEL_CALL_DISCONTINUOUS_FIXED_DATA_STATUSES: [
    'SENT',
    'INITIATED',
    'NOTIFIED',
  ],
  REHIREABLE_CALL_DISCONTINUOUS_FIXED_DATA_STATUSES: [
    'REJECTED_WITH_STORED_CERTIFICATE',
    'NOT_ANSWERED_WITH_STORED_CERTIFICATE',
    'REJECTED',
    'NOT_ANSWERED',
  ],
  PROCESS_SUCCESSFUL_STATUSES: [
    'COMPLETED_SUCCESS',
    'SIGNED',
    'SIGNED_ON_PAPER',
    'ACCEPTED',
    'STATUS_IMPORTED',
  ],

  SAP_ASSIGNABLE_STATUSES: ['WAITING_FOR_HR_DATA', 'ACCEPTED'],
  SEND_EMAIL_COLLABORATOR_START_STATUSES: ['QUEUED_SEND_EMAIL_PENDING'],
  LEAVE_TRANSITIONS: {
    CANCELLABLE_STATUSES: ['WAITING_TO_BE_EXECUTED', 'WAITING_TO_START'],
    LEAVE_STATUSES: ['ONGOING_LEAVE_OF_ABSENCE'],
  },
  SIGN_ON_PAPER: [
    'WAITING_FOR_DIRECTOR_VALIDATION',
    'WAITING_FOR_DIRECTOR_AND_LEGAL_VALIDATION',
    'WAITING_FOR_HR_DATA',
    'WAITING_FOR_LEGAL_VALIDATION',
    'WAITING_TO_SIGN_CONTRACT',
    'WAITING_TO_SEND_CONTRACT',
    'QUEUED_SEND_EMAIL_PENDING',
    'CONTRACT_SENT',
  ],
}

export const collaboratorStatuses = [
  'DELETED',
  'EXPATRIATE',
  'HIRED',
  'HIRED_CDI_DF_ACTIVE',
  'HIRED_CDI_DF_INACTIVE',
  'HIRING_PROCESS',
  'LEAVE_OF_ABSENCE',
  'LEAVE_PROCESS',
  'WAITING_FOR_HR_DATA_AND_SIGNATURE',
  'CONTRACT_CHANGE_PROCESS',
  'ONGOING_CDI_DF_CALL_PROCESS',
]

export const collaboratorStatusesObj = collaboratorStatuses.reduce(
  (obj, str) => {
    obj[str] = str
    return obj
  },
  {},
)

export const processStatuses = [
  'COMPLETED_SUCCESS',
  'WAITING_FOR_HR_DATA',
  'WAITING_FOR_COLLABORATOR_DATA',
  'WAITING_TO_BE_EXECUTED',
  'WAITING_TO_SEND_CONTRACT',
  'QUEUED_SEND_EMAIL_PENDING',
  'WAITING_TO_SEND_CONTRACT',
  'WAITING_TO_SIGN_CONTRACT',
  'WAITING_TO_START',
  'WAITING_FOR_DIRECTOR_VALIDATION',
  'WAITING_FOR_LEGAL_VALIDATION',
  'WAITING_FOR_DIRECTOR_AND_LEGAL_VALIDATION',
  'SIGNED',
  'SIGNED_ON_PAPER',
  'WAITING_TO_BE_EXECUTED',
  'EXPIRED_SIGNATURE',
]

export const processStatusesObj = processStatuses.reduce((obj, str) => {
  obj[str] = str
  return obj
}, {})

export const mutationProcessStatuses = [
  'PENDING_MUTATION_COLLABORATOR_PROCESS',
  'CANCEL_MUTATION_COLLABORATOR_PROCESS',
  'FINISH_MUTATION_COLLABORATOR_PROCESS',
]

export const timebaseChangeProcessStatuses = [
  'CANCELLED',
  'SIGNED',
  'SIGNED_ON_PAPER',
  'NOT_SIGNED',
  'PENDING_SENDING',
  'PENDING_SIGNATURE',
]
export const complementaryPactProcessStatuses = [
  'CANCELLED',
  'SIGNED',
  'SIGNED_ON_PAPER',
  'NOT_SIGNED',
  'PENDING_SENDING',
  'PENDING_SIGNATURE',
]
export const shiftReductionInitialStatus = [
  'SIGNED_ON_PAPER',
  'PENDING_SIGNATURE',
]

export const positionsWithDefaultSiteTeam = [
  'DIRECTOR DE TIENDA',
  'DIRECTOR TALLER REGIONAL',
  'DIRECTOR ALMACÉN',
  'ORGANIZADOR SEGURIDAD EXP',
]

export const leaveOfAbsenceLeaveReasons = [
  'VOLUNTARY_LEAVE',
  'FAMILY_CARE_LEAVE',
  'MATERNITY_LEAVE',
  5,
  6,
  7,
]
export const leaveReasonForLeave = [
  'CONSUMED_TEMPORARY_INCAPACITY',
  'VOLUNTARY_RESIGNATION',
  'DISMISSAL',
  'CONTRACT_END',
  'HAS_NOT_STARTED_TO_WORK',
  'NOT_PASSED_TRIAL',
  'PENSIONIST',
]

export const leaveReasons = [
  'CONSUMED_TEMPORARY_INCAPACITY',
  'VOLUNTARY_RESIGNATION',
  'DISMISSAL',
  'MATERNITY_LEAVE',
  'FAMILY_CARE_LEAVE',
  'VOLUNTARY_LEAVE',
  'CONTRACT_END',
  'HAS_NOT_STARTED_TO_WORK',
  'NOT_PASSED_TRIAL',
  'PENSIONIST',
]

export const leaveProcessTypes = {
  LEAVE_OF_ABSENCE_PROCESS_COUNTRY: 'LEAVE_OF_ABSENCE_PROCESS_COUNTRY',
  LEAVE_PROCESS_COUNTRY: 'LEAVE_PROCESS_COUNTRY',
}

export const leaveOfAbsenceProcessTypes = ['LEAVE_OF_ABSENCE_PROCESS_COUNTRY']

export const contractTypeFullPartial = {
  FULL_TIME: 'TC',
  PARTIAL_TIME: 'TP',
}

export const contractTypeDuration = {
  CDD: 'CDD',
  CDI: 'CDI',
}

export const contractTypes = [
  'CDD_VACATION',
  'CDD_90_DAYS',
  'CDI',
  'SUBSTITUTION',
  'INTERNSHIP',
  'CDI_DF',
  'CDI_WEEKEND',
  'CDI_WEEKEND_90_DAYS',
]

export const contractTypesObj = contractTypes.reduce((obj, str) => {
  obj[str] = str
  return obj
}, {})
export const collaboratorPositionNumberCodes = [
  '50075948', // AZAFATA/O CAJA RECEPCIÓN
  '50000080', // VENDEDOR(A) DEPORTIVO(A)
  '50000061', // AZAFATA/O RECEPCIÓN
  '50000079', // TÉCNICO(A) TALLER
  '50847129', // TÉCNICO(A) TALLER REGIONAL
  '50000053', // OPERARIO TÉCNICO BULTOS
]
export const responsiblePositionNumberCodes = [
  '50686673', // RESPONSABLE UNIVERSO LOGÍSTICA
  '50164252', // RESPONSABLE MUELLE
  '50847131', // RESPONSABLE TALLER REGIONAL
  '50164246', // RESPONSABLE UNIVERSO
]
export const organizerPositionNumberCodes = [
  '50036861', // ORGANIZADOR SEGURIDAD EXP
  '50000078', // ORGANIZADOR
  '50082896', // ORGANIZADORA ECONÓMICA
]
export const directorPositionNumberCodes = [
  '50000072', // DIRECTOR DE TIENDA
  '50847130', // DIRECTOR TALLER REGIONAL
  '50000059', // DIRECTOR ALMACÉN
]
export const leaderPositionNumberCodes = [
  '50975175', // LÍDER ÁREA
  '50975176', // LÍDER ZONA
]

export const allowedPositionNumbersToHireByUserRole = {
  admin: [
    ...collaboratorPositionNumberCodes,
    ...responsiblePositionNumberCodes,
    ...organizerPositionNumberCodes,
    ...directorPositionNumberCodes,
    ...leaderPositionNumberCodes,
  ],
  directorCoach: [
    ...collaboratorPositionNumberCodes,
    ...responsiblePositionNumberCodes,
    ...organizerPositionNumberCodes,
    ...directorPositionNumberCodes,
  ],
  director: [
    ...collaboratorPositionNumberCodes,
    ...responsiblePositionNumberCodes,
    ...organizerPositionNumberCodes,
  ],
  responsible: [...collaboratorPositionNumberCodes],
  collaborator: [],
  lawyer: [],
  organizer: [
    ...collaboratorPositionNumberCodes,
    ...responsiblePositionNumberCodes,
  ],
  patron: [],
  responsibleCollaborator: [],
  multicenterLeader: [],
}

export const contractWeeklyBasetimes = {
  TC: {
    min: 40,
    max: 40,
  },
  TP: {
    min: 4,
    max: 34,
  },
}

export const maxWeeklyTimebase = 40

export const requiredTimebaseForComplementaryPact = {
  min: 15,
  max: 34,
}

export const standardNumberOfWeeksInYear = 52
export const TCContractTypesCodes = {
  CDI_TC: '100',
  CDI_OTHER_CAPABILITIES_TC: '130',
  CDI_FIXED_DISCONTINUOUS: '300',
  CDD_VACATION_TC: '402',
  CDD_90DAYS_TC: '405',
  CDD_OTHER_CAPABILITIES_TC: '430',
  CDD_CONTRACT_CERTAIN_DURATION_TC: '403',
  SUBSTITUTION_TC: '410',
  PRACTICES_CONTRACT_TC: '420',
}

export const TPContractTypeCodes = {
  CDI_TP: '200',
  CDI_OTHER_CAPABILITIES_TP: '230',
  CDI_WEEKEND: '201',
  CDD_VACATION_TP: '502',
  CDD_90DAYS_TP: '505',
  CDD_OTHER_CAPABILITIES_TP: '530',
  CDD_CONTRACT_CERTAIN_DURATION_TP: '503',
  SUBSTITUTION_TP: '510',
  PRACTICES_CONTRACT_TP: '520',
}

export const contractsTime = {
  TP: 'TP',
  TC: 'TC',
}
export const contractTypesCodes = {
  CDI_TP: '200',
  CDI_TC: '100',
  CDI_OTHER_CAPABILITIES_TC: '130',
  CDI_OTHER_CAPABILITIES_TP: '230',
  CDI_FIXED_DISCONTINUOUS: '300',
  CDI_WEEKEND: '201',
  CDD_VACATION_TP: '502',
  CDD_VACATION_TC: '402',
  CDD_90DAYS_TP: '505',
  CDD_90DAYS_TC: '405',
  CDD_OTHER_CAPABILITIES_TP: '530',
  CDD_OTHER_CAPABILITIES_TC: '430',
  CDD_CONTRACT_CERTAIN_DURATION_TC: '403',
  CDD_CONTRACT_CERTAIN_DURATION_TP: '503',
  SUBSTITUTION_TP: '510',
  SUBSTITUTION_TC: '410',
  PRACTICES_CONTRACT_TC: '420',
  PRACTICES_CONTRACT_TP: '520',
}

export const substitutionContractTypeCodes = {
  SUBSTITUTION_TP: '510',
  SUBSTITUTION_TC: '410',
}
export const cdd90DaysContractTypeCodes = {
  CDD_90DAYS_TP: '505',
  CDD_90DAYS_TC: '405',
}
export const cddVacationsContractTypeCodes = {
  CDD_VACATION_TP: '402',
  CDD_90DAYS_TC: '502',
}
export const cdiContractTypeCodes = {
  CDI_TP: '200',
  CDI_TC: '100',
  CDI_DF: '300',
  CDI_OTHER_CAPABILITIES_TC: '130',
  CDI_OTHER_CAPABILITIES_TP: '230',
  CDI_WEEKEND: '201',
}
export const cdiWeekend90DaysContractTypeCodes = {
  CDI_WEEKEND_90_DAYS_TP: '700',
  CDI_WEEKEND_90_DAYS_TC: '600',
}

export const voluntaryResignationStates = {
  WAITING_TOKEN_VALIDATION_CREATION: 'WAITING_TOKEN_VALIDATION_CREATION',
  WAITING_TOKEN_VALIDATION_CANCELLATION: 'WAITING_TOKEN_VALIDATION_CANCELLATION',
  INITIATED: 'INITIATED',
  CANCELLED: 'CANCELLED',
  EXECUTED: 'EXECUTED',
  SCHEDULED: 'SCHEDULED',
  EXPIRED: 'EXPIRED'
}
export const permanentSeasonalContractTypeCode = '300'

export const contractProcessTypes = {
  REHIRING_CONTRACT_PROCESS_COUNTRY: 'REHIRING_CONTRACT_PROCESS_COUNTRY',
  REHIRING_FROM_LEAVE_OF_ABSENCE_PROCESS_COUNTRY:
    'REHIRING_FROM_LEAVE_OF_ABSENCE_PROCESS_COUNTRY',
  CALL_DISCONTINUOUS_FIXED_PROCESS: 'CALL_DISCONTINUOUS_FIXED_PROCESS',
  IMPORT_CONTRACT_PROCESS_COUNTRY: 'IMPORT_CONTRACT_PROCESS_COUNTRY',
  HIRING_CONTRACT_PROCESS_COUNTRY: 'HIRING_CONTRACT_PROCESS_COUNTRY',
  CONVERSION_CONTRACT_PROCESS_COUNTRY: 'CONVERSION_CONTRACT_PROCESS_COUNTRY',
  EXTENSION_CONTRACT_PROCESS_COUNTRY: 'EXTENSION_CONTRACT_PROCESS_COUNTRY',
}

export const contractProcessTypesArray = [
  'REHIRING_CONTRACT_PROCESS_COUNTRY',
  'REHIRING_FROM_LEAVE_OF_ABSENCE_PROCESS_COUNTRY',
  'HIRING_CONTRACT_PROCESS_COUNTRY',
  'CONVERSION_CONTRACT_PROCESS_COUNTRY',
  'EXTENSION_CONTRACT_PROCESS_COUNTRY',
]

export const aggregates = {
  HIRING_CONTRACT_PROCESS_COUNTRY: 'hiring',
  CONVERSION_CONTRACT_PROCESS_COUNTRY: 'conversion',
  EXTENSION_CONTRACT_PROCESS_COUNTRY: 'extension',
  REHIRING_CONTRACT_PROCESS_COUNTRY: 'rehiring',
  REHIRING_FROM_LEAVE_OF_ABSENCE_PROCESS_COUNTRY: 'rehiringFromLeaveOfAbsence',
}

export const peopleWithDisabilityContractCodes = ['130', '230', '430', '530']

export function urlQueryStringBuilder(payload = {}) {
  const queryOptions = []
  if ({}.hasOwnProperty.call(payload, 'sort')) {
    queryOptions.push(`sort=${payload.sort}`)
  }

  if ({}.hasOwnProperty.call(payload, 'filters')) {
    Object.keys(payload.filters).forEach((key) => {
      if (payload.filters[key] && payload.filters[key].length) {
        return queryOptions.push(`filter[${key}]=${payload.filters[key]}`)
      }
    })
  }

  let queryString = queryOptions.length > 0 ? '?' + queryOptions.join('&') : ''
  queryString = encodeURI(queryString)

  return queryString
}

export const REACTIVATION_ACTIONS = {
  ONLY_UNDO_LEAVE_EXECUTED_BY_MISTAKE: 'ONLY_UNDO_LEAVE_EXECUTED_BY_MISTAKE',
  UNDO_LEAVE_EXECUTED_BY_MISTAKE_AND_CREATE_CONVERSION_CDI:
    'UNDO_LEAVE_EXECUTED_BY_MISTAKE_AND_CREATE_CONVERSION_CDI',
  UNDO_LEAVE_EXECUTED_BY_MISTAKE_AND_CREATE_CONVERSION_CDI_DF:
    'UNDO_LEAVE_EXECUTED_BY_MISTAKE_AND_CREATE_CONVERSION_CDI_DF',
  UNDO_LEAVE_EXECUTED_BY_MISTAKE_AND_CREATE_CONTRACT_EXTENSION:
    'UNDO_LEAVE_EXECUTED_BY_MISTAKE_AND_CREATE_CONTRACT_EXTENSION',
  UNDO_LEAVE_EXECUTED_BY_MISTAKE_AND_EDIT_CONTRACT_END_DATE:
    'UNDO_LEAVE_EXECUTED_BY_MISTAKE_AND_EDIT_CONTRACT_END_DATE',
}

export const HOUR_TYPOLOGY_MY_GAME = [
  'COMMERCIAL',
  'NON_COMMERCIAL',
  'CASH',
  'HOLIDAY',
]

export const HOUR_TYPOLOGY_PERMISSION_TYPES = ['OPTIONAL', 'IMPOSED']
export const SITE_TYPE_CODES = {
  CENTRALSERVICES: 'CENTRALSERVICES',
  STORE: 'STORE',
  WAREHOUSE: 'WAREHOUSE',
  SERVICECENTER: 'SERVICECENTER',
}

export const FICTITIOUS_TYPES = ['REAL', 'EXPLOITATION']

export const schedulableCollaboratorProcesses = [
  'CALL_DISCONTINUOUS_FIXED_PROCESS',
  'COMPLEMENTARY_PACT_PROCESS_COUNTRY',
  'CONVERSION_CONTRACT_PROCESS_COUNTRY',
  'EXTENSION_CONTRACT_PROCESS_COUNTRY',
  'HIRING_CONTRACT_PROCESS_COUNTRY',
  'LEAVE_OF_ABSENCE_EXTENSION_PROCESS',
  'LEAVE_OF_ABSENCE_PROCESS_COUNTRY',
  'LEAVE_PROCESS_COUNTRY',
  'MUTATION_COLLABORATOR_PROCESS_COUNTRY',
  'POSITION_CHANGE_PROCESS_COUNTRY',
  'REHIRING_CONTRACT_PROCESS_COUNTRY',
  'REHIRING_FROM_LEAVE_OF_ABSENCE_PROCESS_COUNTRY',
  'RETURN_TO_CDI_WEEKEND_CONTRACT_PROCESS',
  'SHIFT_REDUCTION_PROCESS_COUNTRY',
  'TEAM_CHANGE_PROCESS_COUNTRY',
  'TIMEBASE_CHANGE_PROCESS_COUNTRY',
]

export const schedulableCollaboratorProcessStatuses = [
  'CANCELLED',
  'CANCEL_MUTATION_COLLABORATOR_PROCESS',
  'COMPLETED_SUCCESS',
  'ENDED',
  'ENDED_BY_VOLUNTARY_RESIGNATION',
  'EXPIRED_SIGNATURE',
  'FINISH',
  'FINISH_MUTATION_COLLABORATOR_PROCESS',
  'FINISHED',
  'IN_REHIRING_PROCESS',
  'NOT_SIGNED',
  'ONGOING',
  'ONGOING_LEAVE_OF_ABSENCE',
  'PENDING_MUTATION_COLLABORATOR_PROCESS',
  'PENDING_SENDING',
  'PENDING_SIGNATURE',
  'QUEUED_SEND_EMAIL_PENDING',
  'REHIRED',
  'SIGNED',
  'SIGNED_ON_PAPER',
  'STARTED_LEAVE_PROCESS',
  'STATUS_EXECUTED',
  'STATUS_IMPORTED',
  'SUCCESSFULLY_COMPLETED',
  'WAITING_FOR_COLLABORATOR_DATA',
  'WAITING_FOR_DIRECTOR_AND_LEGAL_VALIDATION',
  'WAITING_FOR_DIRECTOR_VALIDATION',
  'WAITING_FOR_HR_DATA',
  'WAITING_FOR_LEGAL_VALIDATION',
  'WAITING_TO_BE_EXECUTED',
  'WAITING_TO_SEND_CONTRACT',
  'WAITING_TO_START',
]
